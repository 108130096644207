import * as React from "react";

const SvgRestroom = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 0 512.001 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M488.316 155.32a100.791 100.791 0 00-3.906-4.336C465.56 168.954 440.05 180 412.016 180c-28.04 0-53.547-11.047-72.399-29.016a98.178 98.178 0 00-3.902 4.336c-18.992 22.395-27.133 51.84-22.34 80.801l43.77 263.34A15.003 15.003 0 00371.94 512h80.145a15 15 0 0014.797-12.54l43.773-263.347c4.793-28.953-3.351-58.398-22.34-80.793zm0 0M60.203 499.465A15.002 15.002 0 0075 512h80c7.332 0 13.59-5.3 14.797-12.535L182.707 422H47.293zm0 0M205.625 198.996a91.472 91.472 0 00-20.352-46.047C166.653 169.754 142 180 115 180s-51.652-10.246-70.273-27.05a91.433 91.433 0 00-20.348 46.046L.14 374.953A15.002 15.002 0 0015 392h200a15.002 15.002 0 0014.86-17.047zm0 0M115 150c41.355 0 75-33.645 75-75S156.355 0 115 0 40 33.645 40 75s33.645 75 75 75zm0 0M412 150c41.355 0 75-33.645 75-75S453.355 0 412 0s-75 33.645-75 75 33.645 75 75 75zm0 0" />
    </svg>
  );
};

export default SvgRestroom;
