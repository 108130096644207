import * as React from "react";

const SvgStew = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 -32 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M407 70h-61V35c0-19.3-15.7-35-35-35H201c-19.3 0-35 15.7-35 35v35h-61c-32.59 0-60.379 20.902-70.7 50h443.4c-10.321-29.098-38.11-50-70.7-50zM196 35c0-2.758 2.242-5 5-5h110c2.758 0 5 2.242 5 5v35H196zm0 0M497 150H15c-8.285 0-15 6.715-15 15s6.715 15 15 15h15v192c0 41.355 33.645 75 75 75h302c41.355 0 75-33.645 75-75v-74.578A44.814 44.814 0 01467 300c-24.813 0-45-20.188-45-45v-13c0-8.27-6.73-15-15-15s-15 6.73-15 15v13c0 24.813-20.188 45-45 45s-45-20.188-45-45v-75h30v75c0 8.27 6.73 15 15 15s15-6.73 15-15v-13c0-24.813 20.188-45 45-45s45 20.188 45 45v13c0 8.27 6.73 15 15 15s15-6.73 15-15v-75h15c8.285 0 15-6.715 15-15s-6.715-15-15-15zm0 0" />
    </svg>
  );
};

export default SvgStew;
