import * as React from "react";

const SvgCrepe = (props) => {
  return (
    <svg
      height={512}
      viewBox="0 0 512.869 512.869"
      width={512}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M255.5 0C177.958 0 100.655 24.093 33.732 68.525h114.833c14.09 0 27.931 1.229 41.411 3.608 15.099-20.307 39.266-33.49 66.458-33.49s51.359 13.183 66.458 33.49a238.152 238.152 0 0141.411-3.608h116.536a402.134 402.134 0 00-84.955-43.503C351.023 8.419 303.791 0 255.5 0z" />
      <path d="M223.105 80.535a227.895 227.895 0 0133.329 14.169 228.113 228.113 0 0133.329-14.169c-9.094-7.428-20.699-11.892-33.329-11.892s-24.235 4.465-33.329 11.892zM148.565 98.525H14.171l102.786 178.031h16.118c3.381-31.703 13.436-62.953 30.182-91.957 16.385-28.378 37.83-52.291 62.881-70.9-24.055-9.899-50.281-15.174-77.573-15.174zM395.912 276.556L498.698 98.525H364.303c-73.08 0-138.525 37.785-175.065 101.074-14.032 24.304-22.654 50.421-25.909 76.957zM192.886 408.069l58.986 102.166c1.323 2.291 3.425 2.633 4.561 2.633 1.137 0 3.242-.343 4.567-2.639l58.983-102.161H192.886zM336.99 378.612l41.602-72.056H134.277l41.288 71.513s160.165.199 161.425.543z" />
    </svg>
  );
};

export default SvgCrepe;
