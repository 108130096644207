import * as React from "react";

const SvgCorndog = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 0 512 512.001"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M13.16 498.844c17.547 17.543 46.094 17.543 63.64 0l77.973-77.973c-13.57-6.437-26.472-14.746-37.687-25.957-11.211-11.21-19.516-24.117-25.953-37.684L13.16 435.203c-17.547 17.547-17.547 46.094 0 63.64zm0 0M498.926 13.074c-15.867-15.87-40.656-17.238-58.25-4.402 13.32 6.41 26.008 14.586 37.035 25.617 11.031 11.027 19.207 23.715 25.617 37.035 12.836-17.59 11.469-42.379-4.402-58.25zm0 0M194.05 307.242c7.02 1.41 13.95-.695 19.016-5.758 5.067-5.066 7.168-11.996 5.758-19.02a50.954 50.954 0 0113.961-46.112 50.958 50.958 0 0146.117-13.961c7.024 1.406 13.95-.696 19.016-5.762 5.066-5.063 7.168-11.992 5.762-19.016-3.36-16.781 1.863-34.015 13.96-46.113s29.333-17.32 46.114-13.965c7.023 1.41 13.953-.691 19.02-5.758 5.062-5.066 7.164-11.996 5.757-19.015a50.958 50.958 0 0113.961-46.117 50.952 50.952 0 0146.117-13.961c1.586.316 2.993.054 4.48.035C411.954 14.91 347.884 15.625 308.009 55.5L138.3 225.207c-39.266 39.266-40.45 101.941-4.375 143.133-.051-.348.117-.672.047-1.02-2.891-18.945 2.859-35.57 14.894-47.027 12.028-11.426 28.91-16.313 45.184-13.05zm0 0" />
      <path d="M473.426 78.602c-9.352 4.136-19.934 5.648-30.703 3.496-7.024-1.407-13.95.699-19.016 5.761-5.066 5.067-7.168 11.993-5.762 19.016a50.937 50.937 0 01-13.96 46.113 50.95 50.95 0 01-46.114 13.965c-7.023-1.41-13.953.692-19.016 5.758-5.07 5.066-7.171 11.992-5.761 19.02a50.94 50.94 0 01-13.961 46.109 50.95 50.95 0 01-46.113 13.965c-7.024-1.41-13.954.691-19.02 5.757s-7.168 11.993-5.758 19.02a50.95 50.95 0 01-13.96 46.113 50.961 50.961 0 01-46.114 13.961c-6.816-1.367-13.602.59-18.625 5.387-5.047 4.797-7.363 11.465-6.34 18.293 2.082 10.496.813 20.629-2.77 29.777 40.114 23.008 92.133 17.809 126.36-16.418L456.5 203.988c33.957-33.949 39.3-85.406 16.926-125.386zm0 0" />
    </svg>
  );
};

export default SvgCorndog;
