import * as React from "react";

const SvgLiquor = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path d="M351.688 132.917l-53.021-13.25v-13A10.66 10.66 0 00309.334 96V10.667A10.662 10.662 0 00298.667 0h-85.333a10.66 10.66 0 00-10.667 10.667V96a10.66 10.66 0 0010.667 10.667v13l-53.021 13.25C141.292 137.688 128 154.708 128 174.313v295.021c0 23.52 19.146 42.666 42.667 42.666h170.667c23.52 0 42.666-19.146 42.666-42.667v-295.02c0-19.605-13.292-36.625-32.312-41.396zm-10.355 325.75a10.66 10.66 0 01-10.667 10.667H181.333a10.66 10.66 0 01-10.667-10.667v-256c0-3.563 1.792-6.896 4.75-8.875 1.417-.938 35.208-23.125 80.583-23.125s79.167 22.188 80.583 23.125a10.697 10.697 0 014.75 8.875v256z" />
      <path d="M309.333 320H202.667A10.66 10.66 0 00192 330.667a10.66 10.66 0 0010.667 10.667h106.667a10.66 10.66 0 0010.667-10.667A10.662 10.662 0 00309.333 320zM309.333 384H202.667c-5.896 0-10.667 4.771-10.667 10.667s4.771 10.667 10.667 10.667h106.667c5.896 0 10.667-4.771 10.667-10.667S315.229 384 309.333 384z" />
      <circle cx={256} cy={256} r={42.667} />
    </svg>
  );
};

export default SvgLiquor;
