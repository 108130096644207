import * as React from "react";

const SvgPizza = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M418.863 313.426c-18.234-50.266-47.86-96.817-85.668-134.621-37.808-37.813-84.36-67.434-134.62-85.668l-14.102-5.117.203-.555-63.364 139.402C162.234 235.328 193 271.57 193 315c0 49.707-40.297 90-90 90-20.422 0-39.254-6.805-54.355-18.27L1.344 490.793a15 15 0 003.05 16.812 14.993 14.993 0 0016.813 3.051l403.328-183.332-.555.2zm-135.508-53.07c-9.75 9.75-22.554 14.62-35.355 14.62-12.805 0-25.61-4.87-35.355-14.62-19.497-19.493-19.497-51.215 0-70.711 9.75-9.747 22.554-14.618 35.355-14.618 12.805 0 25.61 4.871 35.355 14.618 19.493 19.496 19.493 51.214 0 70.71zm70.25 35.253c-5.859 5.856-15.355 5.856-21.214 0-5.856-5.859-5.856-15.355 0-21.214 5.859-5.86 15.355-5.86 21.214 0 5.856 5.855 5.856 15.355 0 21.214zm0 0" />
      <path d="M511.098 272.793c-22.508-62.035-59.07-119.492-105.735-166.156C358.695 59.973 301.238 23.41 239.207.902c-7.36-2.668-15.535.77-18.773 7.895l-23.551 51.812 11.926 4.325c54.359 19.722 104.707 51.765 145.601 92.656 40.89 40.894 72.93 91.242 92.656 145.601l4.325 11.926 51.812-23.55c7.125-3.243 10.567-11.415 7.895-18.774zm0 0M248 205.023c-5.36 0-10.383 2.075-14.145 5.836-7.796 7.797-7.796 20.485 0 28.286 3.762 3.761 8.786 5.832 14.141 5.832 5.36 0 10.379-2.07 14.14-5.832 7.801-7.801 7.801-20.489.005-28.286-3.762-3.761-8.786-5.836-14.141-5.836zm0 0M163 315c0-31.313-23.992-57.008-54.594-59.746L61.543 358.352C72.32 368.66 86.914 375 103 375c33.137 0 60-26.863 60-60zm0 0" />
    </svg>
  );
};

export default SvgPizza;
