import * as React from "react";

const SvgCounter = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M401 347c0-8.27-6.73-15-15-15h-60c-8.27 0-15 6.73-15 15s6.73 15 15 15h60c8.27 0 15-6.73 15-15zm0 0" />
      <path d="M497 222H15c-8.285 0-15 6.715-15 15v200c0 8.285 6.715 15 15 15h126v-60h-15c-24.813 0-45-20.188-45-45s20.188-45 45-45h60c24.813 0 45 20.188 45 45s-20.188 45-45 45h-15v60h170v-60h-15c-24.813 0-45-20.188-45-45s20.188-45 45-45h60c24.813 0 45 20.188 45 45s-20.188 45-45 45h-15v60h126c8.285 0 15-6.715 15-15V237c0-8.285-6.715-15-15-15zm0 0M341 482h-25c-8.285 0-15 6.715-15 15s6.715 15 15 15h80c8.285 0 15-6.715 15-15s-6.715-15-15-15h-25v-30h-30zm0 0M141 482h-25c-8.285 0-15 6.715-15 15s6.715 15 15 15h80c8.285 0 15-6.715 15-15s-6.715-15-15-15h-25v-30h-30zm0 0" />
      <path d="M201 347c0-8.27-6.73-15-15-15h-60c-8.27 0-15 6.73-15 15s6.73 15 15 15h60c8.27 0 15-6.73 15-15zm0 0M15 30h46v31.766C32.367 68.559 11 94.32 11 125v10c0 8.285 6.715 15 15 15h100c8.285 0 15-6.715 15-15v-10c0-30.68-21.367-56.441-50-63.234V30h150v31.766C212.367 68.559 191 94.32 191 125v10c0 8.285 6.715 15 15 15h100c8.285 0 15-6.715 15-15v-10c0-30.68-21.367-56.441-50-63.234V30h150v31.766C392.367 68.559 371 94.32 371 125v10c0 8.285 6.715 15 15 15h100c8.285 0 15-6.715 15-15v-10c0-30.68-21.367-56.441-50-63.234V30h46c8.285 0 15-6.715 15-15s-6.715-15-15-15H15C6.715 0 0 6.715 0 15s6.715 15 15 15zm0 0" />
    </svg>
  );
};

export default SvgCounter;
