import * as React from "react";

const SvgFrenchfries = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="-61 0 512 512.001"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4.89 153.91c-3.808 3.488-5.581 8.688-4.644 13.77L24.305 301H365.69l24.055-133.32c.941-5.082-.832-10.282-4.64-13.77a14.963 14.963 0 00-14.153-3.351l-94.406 26.425a14.965 14.965 0 00-10.285 10.02C256.465 218.704 227.84 240 194.996 240c-32.84 0-61.465-21.297-71.262-52.996a14.973 14.973 0 00-10.285-10.02l-94.406-26.43c-4.996-1.402-10.313-.128-14.152 3.356zm0 0M360.234 331H29.762l10.91 60h308.656zm0 0M46.125 421l14.121 78.68a14.936 14.936 0 007.484 10.445 15.104 15.104 0 0012.832.805c93.047-37.207 135.82-37.207 228.868 0a15.017 15.017 0 0012.832-.805 14.922 14.922 0 007.484-10.445L343.871 421zm0 0M374.996 15.02c0-11.149-11.734-18.403-21.707-13.418l-30 15a15.007 15.007 0 00-8.293 13.418v105.05l47.871-13.398c3.906-1.113 8.004-1.668 12.13-1.668zm0 0" />
      <path d="M224.996 197.55V30.02c0-5.68-3.207-10.875-8.289-13.418l-30-15c-9.973-4.985-21.71 2.27-21.71 13.418v182.542c8.042 7.583 18.421 12.438 30 12.438s21.956-4.855 30-12.45zm0 0M284.996 143.465V80.727l-30-15v89.035c4.086-2.844 8.488-5.27 13.45-6.664zm0 0M134.996 154.77V65.727l-30 15v62.742l16.54 4.629c4.972 1.39 9.374 3.824 13.46 6.672zm0 0M74.996 135.074V15.02c0-11.149-11.734-18.403-21.707-13.418l-30 15a15.007 15.007 0 00-8.293 13.418v89.984c4.113 0 8.211.555 12.176 1.68zm0 0" />
    </svg>
  );
};

export default SvgFrenchfries;
