import * as React from "react";

const SvgEmpanada = (props) => {
  return (
    <svg
      height={512}
      viewBox="0 0 506.604 506.604"
      width={512}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M235.04 107.266c16.017-6.533 32.704-11.181 49.771-13.812l5.227-93.03c-30.389 1.939-60.17 8.57-88.492 19.491zM120.879 207.312c7.72-13.387 16.852-26.111 27.315-37.967l-66.761-66.761c-19.36 21.213-35.529 44.56-48.24 69.377zM94.151 280.874a221.125 221.125 0 0113.451-46.569l-86.79-34.99C9.852 227.106 3.06 256.336.769 286.185zM96.141 363.748c-3.895-16.887-5.618-34.544-5.055-52.651L0 316.278c.807 30.54 6.469 59.949 16.656 87.371zM105.259 392.739L28.73 431.155c12.116 23.722 27.909 45.539 47.149 64.779l10.606 10.606 55.737-55.737c-15.918-17.1-28.34-36.712-36.963-58.064zM169.408 148.132c12.033-10.619 24.958-19.87 38.565-27.662l-33.895-88.396c-25.585 12.865-49.633 29.402-71.432 49.297zM392.802 105.195c21.352 8.623 40.964 21.044 58.066 36.963l55.737-55.737-10.606-10.606c-19.24-19.241-41.058-35.034-64.781-47.15zM315 90.93c16.765-.223 33.119 1.528 48.811 5.147l39.898-79.486C377.417 6.824 349.297 1.22 320.106.064zM418.988 170.529l6.813-10.564c-30.37-26.091-68.721-38.989-108.11-38.989-48.755 0-99.115 19.738-138.015 58.638-70.326 70.326-78.035 178.162-19.649 246.125l10.565-6.813c100.202-64.623 183.775-148.195 248.396-248.397z" />
    </svg>
  );
};

export default SvgEmpanada;
