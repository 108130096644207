import * as React from "react";

const SvgChef = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="-6 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M470 302.04V322c0 41.625-11.621 80.586-31.79 113.813C473.286 429.55 500 398.847 500 362c0-24.484-11.797-46.262-30-59.96zm0 0M30 302.04C11.797 315.737 0 337.515 0 362c0 36.848 26.715 67.547 61.79 73.813C41.62 402.586 30 363.625 30 322zm0 0M60 221.172V270h380v-48.828c27.96-19.52 45-51.777 45-86.172 0-57.898-47.102-105-105-105-16.406 0-32.328 3.762-46.781 10.973C313.504 15.387 282.816 0 250 0s-63.504 15.387-83.219 40.973C152.328 33.762 136.406 30 120 30 62.102 30 15 77.102 15 135c0 34.395 17.04 66.652 45 86.172zm0 0M440 300H60v22c0 104.766 85.234 190 190 190s190-85.234 190-190zm-256 56c-8.285 0-15-6.715-15-15s6.715-15 15-15 15 6.715 15 15-6.715 15-15 15zm66 91c-24.813 0-45-20.188-45-45 0-8.285 6.715-15 15-15s15 6.715 15 15c0 8.27 6.73 15 15 15s15-6.73 15-15c0-8.285 6.715-15 15-15s15 6.715 15 15c0 24.813-20.188 45-45 45zm66-91c-8.285 0-15-6.715-15-15s6.715-15 15-15 15 6.715 15 15-6.715 15-15 15zm0 0" />
    </svg>
  );
};

export default SvgChef;
