import * as React from "react";

const SvgVegetable = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M448.023 275.125c11.391-16.297 17.63-35.781 17.63-56.203 0-54.27-44.15-98.422-98.423-98.422-1.445 0-2.89.031-4.335.098.53-4.29.796-8.594.796-12.907C363.691 48.312 315.383 0 256 0S148.309 48.313 148.309 107.691c0 4.313.265 8.618.796 12.907a94.455 94.455 0 00-4.335-.098c-54.27 0-98.422 44.152-98.422 98.422 0 20.422 6.238 39.906 17.629 56.203C26.507 289.18 0 325.422 0 367.23c0 21.348 6.727 41.66 19.45 58.739 12.464 16.726 29.527 28.875 49.308 35.113 64.113 20.39 107.14 21.813 172.242 21.91v-67.781l-94.371-75.496c-6.469-5.176-7.52-14.617-2.344-21.086 5.176-6.469 14.617-7.52 21.086-2.34L241 376.79v-61.578l-94.371-75.496c-6.469-5.176-7.52-14.617-2.344-21.086 5.176-6.469 14.617-7.516 21.086-2.34L241 276.79V158c0-8.285 6.715-15 15-15s15 6.715 15 15v118.79l75.629-60.505c6.469-5.172 15.906-4.125 21.086 2.344 5.176 6.469 4.125 15.91-2.344 21.082L271 315.21v61.578l75.629-60.504c6.469-5.176 15.906-4.125 21.086 2.344 5.176 6.469 4.125 15.91-2.344 21.082L271 415.21v67.781c65.098-.097 108.125-1.52 172.21-21.898 19.813-6.25 36.876-18.395 49.34-35.125C505.274 408.89 512 388.579 512 367.23c0-41.808-26.508-78.05-63.977-92.105zm0 0M241 482.992V497c0 8.285 6.715 15 15 15s15-6.715 15-15v-14.008c-4.875.008-9.86.008-15 .008s-10.125 0-15-.008zm0 0" />
    </svg>
  );
};

export default SvgVegetable;
