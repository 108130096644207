import * as React from "react";

const SvgServe = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M511.953 353.05c.274-3.921-.973-7.948-3.816-11.1-23.578-26.122-57.125-41.954-94.465-44.575-37.242-2.621-73.055 8.273-100.844 30.664L301.707 337H228c-17.324 0-32.387 9.848-39.902 24.238a44.694 44.694 0 00-5.043 22.985C184.227 408.313 204.62 427 228.738 427H336c8.285 0 15 6.715 15 15s-6.715 15-15 15H229.066c-41.398 0-75.789-33.094-76.062-74.492a74.521 74.521 0 017.61-33.39L67.03 307.84c-14.828-6.535-31.781-5.16-45.355 3.691C8.106 320.38 0 335.336 0 351.54c0 16.816 8.996 32.574 23.477 41.125l160.972 95.031A175.09 175.09 0 00273.418 512H497c8.285 0 15-6.715 15-15V354c0-.32-.027-.633-.047-.95zm0 0M271 50.61V30h10c8.285 0 15-6.715 15-15s-6.715-15-15-15h-50c-8.285 0-15 6.715-15 15s6.715 15 15 15h10v20.61C161.187 57.046 95.52 114.36 76.547 190h358.906C416.48 114.36 350.813 57.047 271 50.61zm0 0M61 250h390c8.285 0 15-6.715 15-15s-6.715-15-15-15H61c-8.285 0-15 6.715-15 15s6.715 15 15 15zm0 0" />
    </svg>
  );
};

export default SvgServe;
