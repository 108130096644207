import * as React from "react";

const SvgCooking = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 -22 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M30 298.953c0 8.285-6.715 15-15 15s-15-6.715-15-15 6.715-15 15-15 15 6.715 15 15zm0 0M121 73.953c0 8.285-6.715 15-15 15s-15-6.715-15-15 6.715-15 15-15 15 6.715 15 15zm0 0M222.266 183.46c3.668 0 7.379-.288 11.093-.878 30.145-4.797 53.653-29.398 57.172-59.828a69.5 69.5 0 00-.719-20.848c-2.406-12.765-10.648-23.308-22.613-28.922-12.328-5.785-26.77-5.304-38.629 1.282-14.508 8.062-22.933 23.324-21.46 38.886.128 1.36.081 2.758-.145 4.16-.93 5.829-5.434 11.012-11.203 12.903-4.336 1.418-8.473 1.012-12.653-1.235a14.994 14.994 0 00-17.949 2.84 14.996 14.996 0 00-2.035 18.063c12.625 21.12 35.05 33.582 59.14 33.578zm0 0" />
      <path d="M505.98 22.52c-6.011-10.41-15.714-17.856-27.324-20.97-11.61-3.109-23.734-1.51-34.144 4.497l-109.121 63a15.013 15.013 0 00-6.989 9.11 15.015 15.015 0 001.5 11.382l11.141 19.297-37.563 82.559L13.195 358.992a14.98 14.98 0 00-6.988 9.106 14.98 14.98 0 001.5 11.382l30 51.961c10.016 17.348 26.188 29.762 45.54 34.946a75.456 75.456 0 0019.534 2.586c12.992 0 25.82-3.407 37.375-10.078l190.528-110c35.812-20.68 48.128-66.641 27.449-102.454l-26.137-45.277 27.477-60.402.425.738c2.782 4.812 7.82 7.5 13.008 7.5 2.543 0 5.121-.648 7.485-2.012l109.117-63c21.488-12.402 28.879-39.98 16.472-61.468zm0 0M19.14 246.48c1.278.34 2.583.512 3.883.512 2.61 0 5.2-.683 7.5-2.012l38.973-22.5 22.5 38.973c2.777 4.813 7.817 7.5 13.004 7.5 2.543 0 5.121-.648 7.484-2.012 7.176-4.14 9.633-13.316 5.489-20.488l-22.5-38.973 38.972-22.5a14.988 14.988 0 006.989-9.109 14.97 14.97 0 00-1.5-11.379c-10.016-17.351-26.188-29.762-45.54-34.945-19.351-5.184-39.562-2.524-56.914 7.492-35.808 20.676-48.125 66.637-27.445 102.453a14.994 14.994 0 009.106 6.988zm0 0" />
    </svg>
  );
};

export default SvgCooking;
