import * as React from "react";

const SvgPancake = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M166 181h180c24.813 0 45-20.188 45-45 0-19.836-12.906-37.723-30.762-43.688C353.953 40.373 309.598 0 256 0c25.648 0 45 32.242 45 75 0 8.29-6.71 15-15 15s-15-6.71-15-15c0-29.031-11.266-45-15-45s-15 15.969-15 45c0 8.29-6.71 15-15 15s-15-6.71-15-15c0-42.758 19.352-75 45-75-53.598 0-97.953 40.371-104.238 92.313C133.906 98.276 121 116.163 121 136c0 24.813 20.188 45 45 45zm0 0M45 512h422c24.852 0 45-21.148 45-46 0-8.285-6.715-15-15-15H15c-8.285 0-15 6.715-15 15 0 24.852 20.148 46 45 46zm0 0M60 421h392c16.57 0 30-13.43 30-30s-13.43-30-30-30H238.238c-6.215 17.422-22.707 30-42.238 30s-36.023-12.578-42.238-30H60c-16.57 0-30 13.43-30 30s13.43 30 30 30zm0 0M256 271c-8.277 0-15 6.723-15 15v45h32.762c-1.68-4.715-2.762-9.715-2.762-15v-30c0-8.277-6.723-15-15-15zm0 0M452 271h-91v45c0 5.285-1.082 10.285-2.762 15H452c16.57 0 30-13.43 30-30s-13.43-30-30-30zm0 0M60 331h91v-60H60c-16.57 0-30 13.43-30 30s13.43 30 30 30zm0 0" />
      <path d="M316 331c8.277 0 15-6.723 15-15v-90c0-5.152.719-10.129 1.762-15h-153.5c1.027 4.86 1.738 9.828 1.738 15v120c0 8.277 6.723 15 15 15s15-6.723 15-15v-60c0-24.813 20.188-45 45-45s45 20.188 45 45v30c0 8.277 6.723 15 15 15zm0 0M361 226v15h91c16.57 0 30-13.43 30-30s-13.43-30-30-30h-46.383c-9.996 13.203-24.16 22.914-40.613 27.258C362.598 213.773 361 219.676 361 226zm0 0M60 241h91v-15a44.732 44.732 0 00-3.605-17.617c-16.61-4.281-30.903-14.047-40.977-27.34-.145-.004-.273-.043-.418-.043H60c-16.57 0-30 13.43-30 30s13.43 30 30 30zm0 0" />
    </svg>
  );
};

export default SvgPancake;
