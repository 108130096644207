import * as React from "react";

const SvgNachos = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="-91 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M93.547 271h32.18l29.02-58.035c2.589-5.176 6.292-9.387 10.41-13.094l-16.739-33.488c-5.188-10.403-21.648-10.403-26.836 0l-41.453 82.906c-3.387 6.754-.676 12.453.656 14.594 1.32 2.137 5.2 7.117 12.762 7.117zm0 0M140.785 323.883c1.32 2.137 5.2 7.117 12.762 7.117h82.906c7.563 0 11.442-4.98 12.762-7.117 1.332-2.14 4.043-7.84.656-14.594l-41.453-82.906c-2.594-5.203-7.617-8.293-13.418-8.293s-10.824 3.09-13.418 8.293l-41.453 82.906c-3.387 6.754-.676 12.453.656 14.594zm0 0" />
      <path d="M0 497c0 8.29 6.71 15 15 15h300c8.29 0 15-6.71 15-15 0-24.621-12.086-47.316-30.465-61C317.915 422.316 330 400.621 330 376V136c0-24.621-12.086-46.316-30.465-60C317.915 62.316 330 39.621 330 15c0-8.29-6.71-15-15-15H15C6.71 0 0 6.71 0 15c0 24.621 12.086 47.316 30.465 61C12.085 89.684 0 111.379 0 136v240c0 24.621 12.086 46.316 30.465 60C12.085 449.684 0 472.379 0 497zm195-46h-60c-8.29 0-15-6.71-15-15s6.71-15 15-15h60c8.29 0 15 6.71 15 15s-6.71 15-15 15zM135 61h60c8.29 0 15 6.71 15 15s-6.71 15-15 15h-60c-8.29 0-15-6.71-15-15s6.71-15 15-15zM53.29 235.871l41.456-82.906c7.676-15.356 23.102-24.875 40.254-24.875s32.578 9.52 40.254 24.875l17.7 35.41c.698-.031 1.343-.285 2.046-.285 17.152 0 32.578 9.52 40.254 24.875l41.457 82.906c7.027 14.05 6.281 30.426-1.965 43.785-8.262 13.36-22.574 21.344-38.293 21.344h-82.906c-15.719 0-30.031-7.984-38.293-21.344-7.277-11.785-8.352-25.844-3.762-38.656H93.547c-15.719 0-30.031-7.984-38.293-21.344-8.246-13.36-8.992-29.734-1.965-43.785zm0 0" />
    </svg>
  );
};

export default SvgNachos;
