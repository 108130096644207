import * as React from "react";

const SvgNoodlesalt = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 15c0 8.29 6.71 15 15 15h16v30H15C6.71 60 0 66.71 0 75s6.71 15 15 15h16v150h30V30h30v151.3c8.594-11.675 18.559-22.163 30-30.843V30h30v103.18c9.586-4.25 19.563-7.633 30-9.785V90h255c8.29 0 15-6.71 15-15s-6.71-15-15-15H181V30h255c8.29 0 15-6.71 15-15s-6.71-15-15-15H15C6.71 0 0 6.71 0 15zm0 0M497 270H15c-8.29 0-15 6.71-15 15v45h512v-45c0-8.29-6.71-15-15-15zm0 0M136 512h240c8.29 0 15-6.71 15-15v-15.836c62.434-6.93 112.582-58.758 119.586-121.164H1.414C8.418 422.406 58.566 474.234 121 481.164V497c0 8.29 6.71 15 15 15zm0 0" />
      <path d="M295.473 240h31.265C313.32 188.39 266.746 150 211 150s-102.32 38.39-115.738 90h31.265c12.426-34.844 45.414-60 84.473-60s72.047 25.156 84.473 60zm0 0" />
      <path d="M159.344 240h103.312c-10.406-17.848-29.543-30-51.656-30s-41.25 12.152-51.656 30zm0 0M348.488 211.71c2.828 6.696 5.41 13.544 7.282 20.747l1.964 7.543h54.922c-10.406-17.848-29.543-30-51.656-30-4.34 0-8.445.82-12.512 1.71zm0 0M445.473 240h31.265C463.32 188.39 416.746 150 361 150c-17.316 0-33.55 4.063-48.379 10.715 7.668 7.176 14.746 14.965 20.813 23.672A88.755 88.755 0 01361 180c39.059 0 72.047 25.156 84.473 60zm0 0" />
    </svg>
  );
};

export default SvgNoodlesalt;
