import * as React from "react";

const SvgTaco = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 -83 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M152.02 64.922c1.539-15.856 6.43-30.633 14.253-43.645-17.921-4.863-32.703-6.16-33.773-6.234-5.305-.438-10.238 1.95-13.285 6.184-.602.84-8.457 12.043-15.977 27.808 14.41 3.903 27.465 10.762 38.723 19.945 3.305-1.449 6.687-2.734 10.059-4.058zm0 0M345.863 21.527c7.739 12.953 12.586 27.637 14.117 43.395 3.372 1.324 6.754 2.61 10.06 4.058 11.491-9.375 24.843-16.363 39.6-20.218-7.48-15.621-15.25-26.7-15.843-27.524-3.047-4.246-7.852-6.636-13.285-6.195-1.09.082-16.332 1.422-34.649 6.484zm0 0M436 75c-13.082 0-25.195 3.648-35.902 9.535 41.808 24.64 77.726 59.559 102.367 101.367C508.355 175.195 512 163.082 512 150c0-41.422-34.578-75-76-75zm0 0M327.813 54.523C318.854 23.125 290.272 0 256 0s-62.855 23.125-71.813 54.523C207.177 48.523 231.157 45 256 45s48.824 3.523 71.813 9.523zm0 0M0 150c0 13.082 3.645 25.195 9.535 35.902 24.64-41.808 60.559-76.726 102.367-101.367C101.195 78.648 89.082 75 76 75c-41.422 0-76 33.578-76 75zm0 0M344.184 147.055c0 8.277 6.722 15 15 15s15-6.723 15-15c0-8.274-6.723-15-15-15s-15 6.722-15 15zm0 0M331 315c0 16.57-13.43 30-30 30s-30-13.43-30-30 13.43-30 30-30 30 13.43 30 30zm0 0" />
      <path d="M385.488 110.71c11.274 8.185 18.696 21.376 18.696 36.345 0 24.812-20.188 45-45 45s-45-20.188-45-45 20.187-45 45-45c7.722 0 14.882 2.132 21.238 5.574C343.578 86.929 301.184 75 256 75 115.39 75 0 189.39 0 330c0 8.29 6.71 15 15 15h234.32c-5.152-8.855-8.32-19.016-8.32-30 0-33.137 26.863-60 60-60s60 26.863 60 60c0 10.984-3.168 21.145-8.32 30H497c8.29 0 15-6.71 15-15 0-93.313-51.531-174.844-126.512-219.29zM106 285c-8.285 0-15-6.715-15-15s6.715-15 15-15 15 6.715 15 15-6.715 15-15 15zm60-30c-24.813 0-45-20.188-45-45s20.188-45 45-45c8.29 0 15 6.71 15 15s-6.71 15-15 15c-8.277 0-15 6.723-15 15s6.723 15 15 15 15-6.723 15-15c0-8.29 6.71-15 15-15s15 6.71 15 15c0 24.813-20.188 45-45 45zm90-60c-8.285 0-15-6.715-15-15s6.715-15 15-15 15 6.715 15 15-6.715 15-15 15zm150 90c-8.285 0-15-6.715-15-15s6.715-15 15-15 15 6.715 15 15-6.715 15-15 15zm0 0" />
    </svg>
  );
};

export default SvgTaco;
