import * as React from "react";

const SvgOrangejuice = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="-52 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M210 142c0-57.898-47.102-105-105-105S0 84.102 0 142s47.102 105 105 105 105-47.102 105-105zm-55.293 56.094a74.958 74.958 0 01-25.965 15.031L105 172l-23.742 41.125a74.949 74.949 0 01-25.965-15.027L79.02 157H31.508C30.52 152.152 30 147.137 30 142s.52-10.152 1.508-15H79.02L55.293 85.906a74.958 74.958 0 0125.965-15.031L105 112l23.742-41.125a74.949 74.949 0 0125.965 15.027L130.98 127h47.512c.988 4.848 1.508 9.863 1.508 15s-.52 10.152-1.508 15H130.98zm0 0M392 0h-50c-24.813 0-45 20.188-45 45v52h30V45c0-8.27 6.73-15 15-15h50c8.285 0 15-6.715 15-15s-6.715-15-15-15zm0 0M375 127H239.16c.55 4.926.84 9.93.84 15 0 27.73-8.41 53.535-22.805 75h163.418l9.27-73.113A15.004 15.004 0 00375 127zm0 0M106.988 276.977l28.133 221.91A14.996 14.996 0 00150 512h180c7.555 0 13.934-5.617 14.883-13.113L376.809 247H189.75c-22.707 18.363-51.46 29.52-82.762 29.977zm0 0" />
    </svg>
  );
};

export default SvgOrangejuice;
