import * as React from "react";

const SvgShrimp = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M486.477 169.734L350.969 305.242A43.181 43.181 0 01354.848 320h151.12A209.771 209.771 0 00512 270c0-36.285-9.254-70.453-25.523-100.266zm0 0M335.785 359.574l84.117 84.117c33.743-22.976 60.461-55.52 76.25-93.691H345.961a43.814 43.814 0 01-10.176 9.574zm0 0M315 183.434c8.285 0 15 6.714 15 15 0 32.164-13.688 61.187-35.535 81.566H311.5c6.52 0 12.7 1.45 18.258 4.031l140.09-140.09C431.492 93 370.53 60 302 60H45c-8.27 0-15-6.73-15-15s6.73-15 15-15h150c8.285 0 15-6.715 15-15s-6.715-15-15-15H45C20.187 0 0 20.188 0 45c0 19.703 12.73 36.473 30.395 42.555 6.304 103.789 90.171 186.98 194.25 192.183C266.727 276.551 300 241.316 300 198.434c0-8.286 6.715-15 15-15zM175 155c-8.285 0-15-6.715-15-15s6.715-15 15-15 15 6.715 15 15-6.715 15-15 15zm0 0M285 367v113h17c32.59 0 63.469-7.465 91.02-20.77L300.79 367zm0 0M131.824 361.203C125.191 373.395 125 385.418 125 386.75s.191 13.355 6.824 25.547c2.297 4.215 5.082 7.957 8.317 11.203-3.235 3.246-6.024 6.988-8.317 11.203-6.633 12.192-6.824 24.215-6.824 25.547s.191 13.355 6.824 25.547C141.02 502.69 158.13 512 180 512c26.219 0 55.336-22.227 75-32v-41.5h-75c-8.285 0-15-6.715-15-15s6.715-15 15-15h75V367c-19.664-9.773-48.781-32-75-32-21.871 0-38.98 9.309-48.176 26.203zm0 0" />
    </svg>
  );
};

export default SvgShrimp;
