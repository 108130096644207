import * as React from "react";

const SvgCottoncandy = (props) => {
  return (
    <svg
      height={682.667}
      viewBox="0 0 512 512"
      width={682.667}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M248.043 30.613c-15.168 15.164-25.172 34.7-28.86 55.996-21.296 3.688-40.831 13.696-55.995 28.86-30.97 30.969-39.11 76.976-21.356 116.668-17.754 39.695-9.613 85.703 21.356 116.675 30.972 30.97 76.984 39.11 116.675 21.36 39.692 17.75 85.7 9.61 116.672-21.36 15.164-15.164 25.172-34.699 28.856-55.996 21.296-3.687 40.832-13.691 55.996-28.855 30.968-30.973 39.113-76.98 21.36-116.676 17.753-39.691 9.608-85.7-21.36-116.672-30.973-30.968-76.98-39.11-116.672-21.355C325.02-8.496 279.012-.355 248.043 30.613zm0 0M76.8 498.84l102.626-102.625c-13.563-6.488-26.32-15.055-37.45-26.192-11.136-11.132-19.703-23.886-26.187-37.449L13.16 435.2c-17.547 17.547-17.547 46.094 0 63.64s46.094 17.548 63.64 0zm0 0" />
    </svg>
  );
};

export default SvgCottoncandy;
