import * as React from "react";

const SvgBurger = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 0 511 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M30.5 361h78.86c-15.368-18.637-38.938-30-63.86-30h-15zm0 0M482.5 271h-452v30h15c36.094 0 70.098 16.934 91 45.234 20.902-28.3 53.906-45.234 90-45.234h256zm0 0M1 410.07C16.094 468.004 73.887 512 136.5 512h240c62.61 0 120.406-43.996 135.5-101.93 2.5-9.597-4.7-19.07-14.613-19.07h-29.88c-31.093 0-60.519 17.57-74.425 45.383l-3.164 6.328a15.003 15.003 0 01-26.836 0l-3.164-6.328C346.012 408.57 317.586 391 286.492 391H15.613C5.7 391-1.5 400.473 1 410.07zm0 0" />
      <path d="M163.64 361H286.5c36.094 0 69.098 16.934 90 45.234 35.86-48.582 87.996-44.363 91-45.234h15v-30h-256c-24.922 0-47.492 11.363-62.86 30zm0 0M15.703 241h481.59c8.887 0 16.191-7.758 15.098-16.574C496.777 98.69 386.39 0 256.5 0 126.605 0 16.219 98.691.61 224.426-.485 233.242 6.82 241 15.702 241zM335.895 95.395c5.859-5.86 15.351-5.86 21.21 0l30 30c5.86 5.859 5.86 15.351 0 21.21s-15.351 5.86-21.21 0l-30-30c-5.86-5.859-5.86-15.351 0-21.21zM241.5 136c0-8.29 6.707-15 15-15 8.29 0 15 6.71 15 15v30c0 8.29-6.71 15-15 15-8.293 0-15-6.71-15-15zm-115.605-10.605l30-30c5.859-5.86 15.351-5.86 21.21 0s5.86 15.351 0 21.21l-30 30c-5.859 5.86-15.351 5.86-21.21 0s-5.86-15.351 0-21.21zm0 0" />
    </svg>
  );
};

export default SvgBurger;
