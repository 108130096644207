import * as React from "react";

const SvgFoodtruck = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 -23 512 511"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M121 435.504c0 16.566-13.43 30-30 30s-30-13.434-30-30c0-16.57 13.43-30 30-30s30 13.43 30 30zm0 0M211 435.504c0 16.566-13.43 30-30 30s-30-13.434-30-30c0-16.57 13.43-30 30-30s30 13.43 30 30zm0 0M421 435.504c0 16.566-13.43 30-30 30s-30-13.434-30-30c0-16.57 13.43-30 30-30s30 13.43 30 30zm0 0M166 255.504c8.277 0 15-6.727 15-15v-15h-30v15c0 8.273 6.723 15 15 15zm0 0M211 240.504c0 8.273 6.723 15 15 15s15-6.727 15-15v-15h-30zm0 0M196 273.797c-7.98 7.2-18.434 11.707-30 11.707s-22.02-4.508-30-11.707c-7.98 7.2-18.434 11.707-30 11.707-5.285 0-10.285-1.082-15-2.766v32.766h150v-32.766c-4.715 1.684-9.715 2.766-15 2.766-11.566 0-22.02-4.508-30-11.707zm0 0M391 225.504v60h85.813l-19.997-60zm0 0M106 255.504c8.277 0 15-6.727 15-15v-15H91v15c0 8.273 6.723 15 15 15zm0 0M331 435.504c0-22.113 12.152-41.25 30-51.656v-68.344h-30zm0 0M503.21 315.504H391v60c33.137 0 60 26.86 60 60h46c8.285 0 15-6.719 15-15v-90a14.99 14.99 0 00-4.395-10.606zm0 0" />
      <path d="M497 135.504H15c-8.285 0-15 6.71-15 15v270c0 8.289 6.71 15 15 15h16c0-33.094 26.91-60 60-60 18.008 0 33.996 8.133 45 20.726 11.004-12.593 26.992-20.726 45-20.726 33.09 0 60 26.906 60 60h60v-225c0-8.29 6.715-15 15-15h181c8.285 0 15-6.719 15-15v-30c0-8.29-6.715-15-15-15zm-226 195c0 8.289-6.71 15-15 15H76.004c-8.29 0-15.004-6.543-15.004-14.828V210.504c0-8.293 6.71-15 15-15h180c8.29 0 15 6.707 15 15v120zm0 0" />
      <path d="M331 225.504h30v60h-30zm0 0M382.71 28.922c7.411-3.707 10.415-12.715 6.708-20.129-3.691-7.426-12.715-10.398-20.129-6.707-24.96 12.976-21.14 9.742-25.32 13.555-7.848-6.266-17.574-9.805-27.996-9.805-9.801 0-19.118 3.105-26.97 8.976-26.526 19.88-59.562 30.82-93.03 30.82-33.473 0-66.504-10.94-92.989-30.835-7.808-5.844-17.109-8.95-26.882-8.95-10.438 0-20.188 3.544-28.055 9.805-4.149-3.793-.277-.539-25.336-13.57-7.43-3.687-16.438-.715-20.129 6.711-3.703 7.41-.703 16.422 6.711 20.125l22.102 11.047c.289.144.609.09.902.21-1.336 5.497-1.676 11.243-.844 17.016 1.7 11.91 7.926 22.426 17.55 29.633 9.38 7.031 19.427 13.098 29.731 18.676h234.524c10.308-5.582 20.36-11.652 29.738-18.688 9.625-7.195 15.852-17.71 17.55-29.62.833-5.77.5-11.516-.835-17.012.293-.125.605-.07.894-.215zm0 0" />
    </svg>
  );
};

export default SvgFoodtruck;
