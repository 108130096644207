import * as React from "react";

const SvgReceipt = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="-71 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M355 0H15C6.715 0 0 6.715 0 15v482c0 8.285 6.715 15 15 15h340c8.285 0 15-6.715 15-15V15c0-8.285-6.715-15-15-15zM161 431H75c-8.285 0-15-6.715-15-15s6.715-15 15-15h86c8.285 0 15 6.715 15 15s-6.715 15-15 15zm0-60H75c-8.285 0-15-6.715-15-15s6.715-15 15-15h86c8.285 0 15 6.715 15 15s-6.715 15-15 15zm0-60H75c-8.285 0-15-6.715-15-15s6.715-15 15-15h86c8.285 0 15 6.715 15 15s-6.715 15-15 15zm0-141H75c-8.285 0-15-6.715-15-15s6.715-15 15-15h86c8.285 0 15 6.715 15 15s-6.715 15-15 15zm134 261h-40c-8.285 0-15-6.715-15-15s6.715-15 15-15h40c8.285 0 15 6.715 15 15s-6.715 15-15 15zm0-60h-40c-8.285 0-15-6.715-15-15s6.715-15 15-15h40c8.285 0 15 6.715 15 15s-6.715 15-15 15zm0-60h-40c-8.285 0-15-6.715-15-15s6.715-15 15-15h40c8.285 0 15 6.715 15 15s-6.715 15-15 15zm-30-171c24.813 0 45 20.188 45 45 0 19.555-12.54 36.227-30 42.422V235c0 8.285-6.715 15-15 15s-15-6.715-15-15v-5h-15c-8.285 0-15-6.715-15-15s6.715-15 15-15h30c8.27 0 15-6.73 15-15s-6.73-15-15-15c-24.813 0-45-20.188-45-45 0-19.555 12.54-36.227 30-42.422V75c0-8.285 6.715-15 15-15s15 6.715 15 15v5h15c8.285 0 15 6.715 15 15s-6.715 15-15 15h-30c-8.27 0-15 6.73-15 15s6.73 15 15 15zm0 0" />
    </svg>
  );
};

export default SvgReceipt;
