import * as React from "react";

const SvgGuitar = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="-30 0 511 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M444.766 76.52l-81-54A14.999 14.999 0 00340.446 35v69.035a54.654 54.654 0 00-25-6.035c-30.329 0-55 24.676-55 55 0 30.328 24.671 55 55 55 30.324 0 55-24.672 55-55V63.027l57.679 38.453c6.89 4.594 16.207 2.735 20.8-4.16 4.595-6.89 2.731-16.207-4.16-20.8zm0 0M249.059 328.281c8.593-15.855 13.086-33.37 13.086-51.281 0-47.723-32.364-90.383-80-107.3V100h15c8.285 0 15-6.715 15-15s-6.715-15-15-15h-15V50h15c8.285 0 15-6.715 15-15s-6.715-15-15-15h-15v-5c0-8.285-6.715-15-15-15h-60c-8.286 0-15 6.715-15 15v5h-15c-8.286 0-15 6.715-15 15s6.714 15 15 15h15v20h-15c-8.286 0-15 6.715-15 15s6.714 15 15 15h15v69.7c-47.637 16.917-80 59.577-80 107.3 0 17.91 4.496 35.426 13.085 51.281l-.171.211c-27.114 34.024-32.172 79.613-13.207 118.98C31.027 487.274 70.316 512 114.379 512h45.531c44.067 0 83.352-24.727 102.527-64.527 18.965-39.368 13.907-84.957-13.207-118.98l-.171-.212zM177.145 413h-80c-8.286 0-15-6.715-15-15s6.714-15 15-15h80c8.285 0 15 6.715 15 15s-6.715 15-15 15zm-40-81c-30.329 0-55-24.672-55-55s24.671-55 55-55 55 24.672 55 55-24.672 55-55 55zm0 0" />
      <path d="M137.145 252c-13.786 0-25 11.215-25 25s11.214 25 25 25c13.785 0 25-11.215 25-25s-11.215-25-25-25zm0 0" />
    </svg>
  );
};

export default SvgGuitar;
