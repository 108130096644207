import * as React from "react";

const SvgAdd = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 0 511 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M195.5 443c0 8.285-6.715 15-15 15s-15-6.715-15-15 6.715-15 15-15 15 6.715 15 15zm0 0M285.5 443c0 8.285-6.715 15-15 15s-15-6.715-15-15 6.715-15 15-15 15 6.715 15 15zm0 0M240.5 497c0 8.285-6.715 15-15 15s-15-6.715-15-15 6.715-15 15-15 15 6.715 15 15zm0 0M330.5 497c0 8.285-6.715 15-15 15s-15-6.715-15-15 6.715-15 15-15 15 6.715 15 15zm0 0M150.5 497c0 8.285-6.715 15-15 15s-15-6.715-15-15 6.715-15 15-15 15 6.715 15 15zm0 0M497.5 33h-79.402C411.789 13.855 393.73 0 372.5 0H26.062C12.047 0 .238 11.469.505 25.484.762 39.066 11.852 50 25.5 50h124c8.285 0 15 6.715 15 15s-6.715 15-15 15H26.062C12.047 80 .238 91.469.505 105.484.762 119.066 11.852 130 25.5 130h124c8.285 0 15 6.715 15 15s-6.715 15-15 15H26.062C12.047 160 .238 171.469.505 185.484.762 199.066 11.852 210 25.5 210h67.121a99.01 99.01 0 00-1.707 1.684c-19.894 20.093-30.691 46.515-30.41 74.394.281 27.602 11.18 53.567 30.687 73.106 19.508 19.539 45.446 30.48 73.04 30.808.18 0 .355.004.53.004 12.028 0 23.813-4.851 32.407-13.355 8.613-8.524 13.348-19.782 13.332-31.704-.035-24.625-19.969-44.683-44.535-44.933-4.153-.043-8.219-1.512-11.137-4.465-2.902-2.934-4.437-6.836-4.324-10.973.223-8.03 7.226-14.566 15.621-14.566h118.75c8.39 0 15.398 6.535 15.617 14.566.113 4.137-1.422 8.04-4.32 10.973-2.918 2.953-6.988 4.422-11.137 4.465-24.566.25-44.504 20.308-44.535 44.933-.016 11.926 4.719 23.184 13.332 31.704 8.602 8.507 20.395 13.355 32.438 13.355.187 0 .37-.004.558-.004 27.77-.347 53.817-11.422 73.352-31.183 19.55-19.782 30.32-45.993 30.32-73.809 0-8.645-1.145-17.355-3.402-25.996L411.918 233H497.5c8.285 0 15-6.715 15-15V48c0-8.285-6.715-15-15-15zm0 0" />
    </svg>
  );
};

export default SvgAdd;
