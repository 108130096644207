import * as React from "react";

const SvgBeer = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 491.696 491.696"
      {...props}
    >
      <path d="M396.86 189.696h-51.816v-8.471c16.876-12.499 27.84-32.548 27.84-55.113 0-33.43-24.055-61.349-55.764-67.356C307.903 24.725 276.851.001 240.165 0c-20.304.001-39.79 7.852-54.44 21.513a63.586 63.586 0 00-16.077-2.072c-25.849 0-48.398 15.683-58.222 38.235a68.614 68.614 0 00-4.037-.118c-37.8 0-68.553 30.753-68.553 68.553 0 20.813 9.335 39.475 24.024 52.058v283.526c0 16.5 13.5 30 30 30h222.184c16.5 0 30-13.5 30-30v-44h51.816c30.878 0 56-25.122 56-56v-116c0-30.878-25.122-55.999-56-55.999zm-92.529-33.031H128.795v61.051c0 10.493-8.507 19-19 19s-19-8.507-19-19v-65.971c-8.393-5.452-13.959-14.902-13.959-25.634 0-16.847 13.706-30.553 30.553-30.553a30.4 30.4 0 0111.032 2.062 18.999 18.999 0 0025.826-16.448c.889-13.307 12.046-23.731 25.401-23.731 4.356 0 8.485 1.06 12.27 3.149 8.375 4.622 18.88 2.297 24.523-5.427C214.409 44.256 226.701 38 240.165 38c22.277 0 40.586 17.408 41.682 39.631a18.993 18.993 0 006.365 13.274 18.981 18.981 0 0013.936 4.744c.884-.062 1.578-.09 2.183-.09 16.847 0 30.553 13.706 30.553 30.553s-13.706 30.553-30.553 30.553zm96.529 205.031c0 2.131-1.869 4-4 4h-51.816v-124h51.816c2.131 0 4 1.869 4 4v116z" />
    </svg>
  );
};

export default SvgBeer;
