import * as React from "react";

const SvgHotFood = (props) => {
  return (
    <svg
      height={512}
      width={512}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      {...props}
    >
      <path d="M15.1 331c19.51 46.56 56.78 84.77 104.9 104.83V466c0 8.28 6.72 15 15 15h242c8.28 0 15-6.72 15-15v-30.17c48.12-20.06 85.39-58.27 104.9-104.83zM497 241H15c-8.28 0-15 6.72-15 15 0 15.38 1.82 30.46 5.3 45h501.4c3.48-14.54 5.3-29.62 5.3-45 0-8.28-6.72-15-15-15zM155.394 176.607c-5.858-5.858-5.858-15.355 0-21.213 10.718-10.719 10.719-28.067 0-38.787-22.391-22.391-22.391-58.822 0-81.213 5.857-5.858 15.355-5.858 21.213 0s5.858 15.355 0 21.213c-10.718 10.719-10.719 28.067 0 38.787 22.391 22.391 22.391 58.822 0 81.213-5.857 5.857-15.356 5.858-21.213 0zM245.394 176.607c-5.858-5.858-5.858-15.355 0-21.213 10.718-10.719 10.719-28.067 0-38.787-22.391-22.391-22.391-58.822 0-81.213 5.857-5.858 15.355-5.858 21.213 0s5.858 15.355 0 21.213c-10.718 10.719-10.719 28.067 0 38.787 22.391 22.391 22.391 58.822 0 81.213-5.857 5.857-15.356 5.858-21.213 0zM335.394 176.607c-5.858-5.858-5.858-15.355 0-21.213 10.717-10.718 10.719-28.067 0-38.787-22.391-22.391-22.391-58.822 0-81.213 5.857-5.858 15.355-5.858 21.213 0s5.858 15.355 0 21.213c-10.717 10.718-10.719 28.067 0 38.787 22.391 22.391 22.391 58.822 0 81.213-5.857 5.857-15.356 5.858-21.213 0z" />
    </svg>
  );
};

export default SvgHotFood;
