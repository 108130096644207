import * as React from "react";

const SvgSoda = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="-121 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M255.035 30c8.403 0 15-6.602 15-15 0-8.402-6.597-15-15-15h-60c-7.2 0-13.2 5.098-14.7 12l-9.597 49h30.598l6-31zm0 0M7.238 238.3c4.2 21.9 9.598 48.9 17.399 81.9 3.3 14.1 5.101 29.1 5.402 44.698.297 15.301-3 37.5-7.203 56.102h224.402c-4.203-18.602-7.5-40.8-7.203-56.102.3-15.597 2.102-30.597 5.403-44.699 7.8-33 13.199-60 17.398-81.898 3.601-19.203 4.8-38.102 4.203-57.301H141.336l16.8 16.8c5.7 6 5.7 15.302 0 21.298l-42.6 42.3c-2.7 3-6.599 4.5-10.5 4.5-3.9 0-7.798-1.5-10.497-4.5l-42.602-42.3a15.4 15.4 0 010-21.297L68.739 181H3.035c-.597 19.2.602 38.098 4.203 57.3zM90.035 316c0-8.402 6.602-15 15-15h60c8.403 0 15 6.598 15 15v60c0 8.398-6.597 15-15 15h-60c-8.398 0-15-6.602-15-15zm0 0" />
      <path d="M105.035 229.602l21.3-21.301-21.3-21-21.3 21zm0 0M120.035 331h30v30h-30zm0 0M2.738 505.7c3 3.898 7.5 6.3 12.297 6.3h240c4.8 0 9.3-2.402 12.3-6.3 2.7-3.9 3.603-9 1.802-13.5l-13.2-41.2h-241.8l-13.2 41.2c-1.8 4.5-.902 9.6 1.801 13.5zm0 0M264.336 151c-4.2-27.898-12.899-55.5-25.8-81.602-2.7-5.097-7.798-8.398-13.5-8.398h-23.7l-18 90zm0 0M31.535 69.398C18.637 95.5 9.937 123.102 5.738 151h147l18-90H45.035c-5.7 0-10.797 3.3-13.5 8.398zm0 0" />
    </svg>
  );
};

export default SvgSoda;
