import * as React from "react";

const SvgDoughnut = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M256 211c-24.813 0-45 20.188-45 45s20.188 45 45 45 45-20.188 45-45-20.188-45-45-45zm0 0" />
      <path d="M383.281 158.66c0-16.465-14.105-29.941-31.437-29.941-27.656 0-54.887-11.496-74.692-31.567-5.492-5.492-13.039-8.554-21.152-8.554-8.246 0-15.79 3.191-21.824 9.242-19.645 19.894-46.453 30.879-75.426 30.879-16.273 0-30.031 14.136-30.031 30.879.176 27.804-11.336 55.27-31.551 75.25-5.465 5.449-8.469 12.98-8.41 21.125.058 8.289 3.281 16.054 9.097 21.867 19.762 19.512 31.028 47.066 30.864 75.5 0 16.465 14.105 29.941 31.437 29.941 27.656 0 54.887 11.496 74.692 31.567 11.058 11.058 30.996 11.293 42.98-.688 19.645-19.894 46.45-30.879 75.41-30.879 16.29 0 30.043-14.136 30.043-30.879-.176-27.804 11.336-55.27 31.555-75.25 5.46-5.449 8.465-12.98 8.406-21.125-.058-8.289-3.281-16.054-9.097-21.867-19.762-19.512-31.028-47.066-30.864-75.5zM256 121c8.285 0 15 6.715 15 15s-6.715 15-15 15-15-6.715-15-15 6.715-15 15-15zm-95.46 39.54c5.858-5.856 15.355-5.856 21.214 0 5.86 5.858 5.86 15.355 0 21.214s-15.356 5.86-21.215 0c-5.855-5.86-5.855-15.356 0-21.215zM136 271c-8.285 0-15-6.715-15-15s6.715-15 15-15 15 6.715 15 15-6.715 15-15 15zm45.754 80.46c-5.86 5.856-15.356 5.856-21.215 0-5.86-5.858-5.86-15.355 0-21.214 5.86-5.86 15.356-5.86 21.215 0s5.86 15.356 0 21.215zM256 391c-8.285 0-15-6.715-15-15s6.715-15 15-15 15 6.715 15 15-6.715 15-15 15zm0-60c-41.352 0-75-33.648-75-75s33.648-75 75-75 75 33.648 75 75-33.648 75-75 75zm95.46 20.46c-5.858 5.856-15.355 5.856-21.214 0-5.86-5.858-5.86-15.355 0-21.214s15.356-5.86 21.215 0c5.855 5.86 5.855 15.356 0 21.215zm0-169.706c-5.858 5.86-15.355 5.86-21.214 0s-5.86-15.356 0-21.215c5.86-5.86 15.356-5.86 21.215 0 5.855 5.86 5.855 15.356 0 21.215zM376 271c-8.285 0-15-6.715-15-15s6.715-15 15-15 15 6.715 15 15-6.715 15-15 15zm0 0" />
      <path d="M256 0C115.39 0 0 115.39 0 256s115.39 256 256 256 256-115.39 256-256S396.61 0 256 0zm179.984 298.438c-14.574 14.398-22.82 34.011-22.703 53.875 0 33.664-26.926 60.968-60.031 60.968-20.887 0-40.105 7.82-54.125 22.016-11.852 11.867-26.746 18.105-43.125 18.105-16.145 0-31.2-6.18-42.438-17.418-14.265-14.457-33.718-22.703-53.406-22.703-33.883 0-61.437-26.926-61.437-60.027.117-20.496-7.895-40.195-22.016-54.145-11.484-11.468-17.844-26.703-17.945-42.933-.117-16.246 6.02-31.375 17.258-42.613 14.574-14.399 22.82-34.012 22.703-53.875 0-33.665 26.926-60.97 60.027-60.97 20.89 0 40.11-7.82 54.125-22.015 23.512-23.57 62.918-23.336 85.563-.687 14.27 14.457 33.722 22.703 53.41 22.703 33.883 0 61.433 26.926 61.433 60.031-.117 20.492 7.899 40.195 22.02 54.14 11.484 11.47 17.84 26.704 17.941 42.934.121 16.246-6.015 31.375-17.254 42.614zm0 0" />
    </svg>
  );
};

export default SvgDoughnut;
