import * as React from "react";

const SvgDimSum = (props) => {
  return (
    <svg
      height={512}
      viewBox="0 0 510 510"
      width={512}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M469.771 212.676c.151-2.291.229-4.776.229-7.542 0-23.807-11.611-46.216-31.061-59.945l-40.435-28.542c16.287 30.257 23.334 63.011 20.727 96.029zM301.061 145.19C281.611 158.919 270 181.328 270 205.135c0 2.526.134 5.043.388 7.542H320.5c-2.598-32.908 4.395-65.553 20.565-95.725zM189.231 212.676h50.54c.151-2.291.229-4.776.229-7.542 0-23.807-11.611-46.216-31.061-59.945l-40.435-28.542c16.287 30.257 23.334 63.012 20.727 96.029zM30 242.676h450v45H30zM111.065 116.952L71.061 145.19C51.611 158.919 40 181.328 40 205.135c0 2.526.134 5.043.388 7.542H90.5c-2.598-32.908 4.395-65.554 20.565-95.725zM369.865 126.867c-15.493 26.937-22.057 56.319-19.253 85.809h38.506c2.804-29.488-3.759-58.871-19.253-85.809zM226.24 120.681a103.282 103.282 0 0128.76 30.87 103.27 103.27 0 0128.76-30.87l2.34-1.652c-6.749-33.5-16.293-62.502-28.44-86.302l-2.758-5.404-2.758 5.404c-12.134 23.771-21.669 52.731-28.416 86.179zM312.737 100.227l34.225-24.159a84.762 84.762 0 00-21.444-21.396L289.54 29.794c9.419 20.645 17.18 44.229 23.197 70.433zM139.865 126.867c-15.493 26.937-22.057 56.319-19.253 85.809h38.506c2.804-29.488-3.759-58.871-19.253-85.809zM330 317.676h90v165h-90zM450 317.676h60v165h-60zM210 317.676h90v165h-90zM90 317.676h90v165H90zM197.092 100.106c5.992-26.068 13.711-49.545 23.074-70.11l-35.685 24.675a84.762 84.762 0 00-21.444 21.396zM0 317.676h60v165H0z" />
    </svg>
  );
};

export default SvgDimSum;
