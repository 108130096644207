import * as React from "react";

const SvgRollcake = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M249.79 383.21c11.339 11.34 31.081 11.34 42.42 0 5.669-5.667 8.79-13.198 8.79-21.21 0-16.57-13.418-30-30-30h-15c-41.352 0-75 33.648-75 75s33.648 75 75 75c88.66 0 135-57.004 135-120 0-66.168-53.832-120-120-120h-30c-66.168 0-120 53.832-120 120 0 35.219 15.527 66.621 39.77 88.594-6.13-13.32-9.77-28-9.77-43.594 0-57.89 47.11-105 105-105h15c33.14 0 60 26.84 60 60 0 16.023-6.238 31.086-17.578 42.422-22.676 22.676-62.168 22.676-84.844 0-5.86-5.86-5.86-15.352 0-21.211 5.86-5.86 15.352-5.86 21.211 0zm0 0" />
      <path d="M316 120H196C88.48 120 0 209.48 0 317s88.48 195 196 195h45c-82.703 0-150-67.297-150-150s67.297-150 150-150h30c82.703 0 150 67.297 150 150s-67.297 150-150 150h45c107.52 0 196-87.48 196-195s-88.48-197-196-197zm0 0M303.852 47.738c12.953 10.844 22.355 25.739 25.699 42.95 28.804 1.726 56.117 8.832 81.015 20.43C435.473 86.612 451 52.617 451 15c0-8.29-6.71-15-15-15h-30c-41.016 0-77.367 18.777-102.148 47.738zm0 0M76 0c-8.29 0-15 6.71-15 15 0 37.617 15.527 71.613 40.434 96.117 24.898-11.597 52.21-18.703 81.015-20.43 3.344-17.21 12.746-32.105 25.7-42.949C183.366 18.778 147.015 0 106 0zm0 0M256 60c-19.531 0-36.023 12.578-42.238 30h84.476C292.023 72.578 275.531 60 256 60zm0 0" />
    </svg>
  );
};

export default SvgRollcake;
