import * as React from "react";

const SvgSpaghetti = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M124.492 497c0 8.285 6.715 15 15 15h233.016c8.285 0 15-6.715 15-15v-15H124.492zm0 0M272 60h-71V45c0-24.813-20.188-45-45-45H15C6.715 0 0 6.715 0 15s6.715 15 15 15h141c8.27 0 15 6.73 15 15v15H15C6.715 60 0 66.715 0 75s6.715 15 15 15h156v15c0 8.27-6.73 15-15 15H15c-8.285 0-15 6.715-15 15s6.715 15 15 15h141c24.813 0 45-20.188 45-45V90h71zm0 0M317 120h150c24.813 0 45-20.188 45-45s-20.188-45-45-45H317c-8.285 0-15 6.715-15 15v60c0 8.285 6.715 15 15 15zm0 0M316 183c75.016 0 136.727 56.969 144.23 130h30.122c-7.61-89.625-82.758-160-174.352-160-56.254 0-106.285 26.559-138.297 67.8-12.93-5.034-26.992-7.8-41.703-7.8-58.43 0-106.676 43.574-114.027 100h30.375c7.086-39.785 41.828-70 83.652-70s76.566 30.215 83.652 70h30.375a114.261 114.261 0 00-7.336-27.977C257.453 259.887 284.742 243 316 243c41.824 0 76.566 30.215 83.652 70h30.375C422.676 256.574 374.43 213 316 213c-36.45 0-68.934 16.957-90.004 43.41a115.659 115.659 0 00-21.652-20.902C230.938 203.438 271.078 183 316 183zm0 0" />
      <path d="M263.09 313h105.82c-6.531-23.082-27.738-40-52.91-40s-46.379 16.918-52.91 40zm0 0M0 347c0 26.156 8.867 48.23 26.36 65.602C65.167 451.148 133.444 452 139.29 452H372.71c5.848 0 74.125-.852 112.93-39.398C503.133 395.23 512 373.156 512 347v-4H0zm0 0M83.09 313h105.82c-6.531-23.082-27.738-40-52.91-40s-46.379 16.918-52.91 40zm0 0" />
    </svg>
  );
};

export default SvgSpaghetti;
