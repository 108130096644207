import * as React from "react";

const SvgLocation = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="-41 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M215 184c-27.906 0-51.426 19.148-58.102 45h116.204c-6.676-25.852-30.196-45-58.102-45zm0 0" />
      <path d="M215 90c-68.926 0-125 56.074-125 125s56.074 125 125 125 125-56.074 125-125S283.926 90 215 90zm90 139c8.285 0 15 6.715 15 15s-6.715 15-15 15H125c-8.285 0-15-6.715-15-15s6.715-15 15-15h1.258c6.34-37.633 36.11-67.402 73.742-73.742V144h-5c-8.285 0-15-6.715-15-15s6.715-15 15-15h40c8.285 0 15 6.715 15 15s-6.715 15-15 15h-5v11.258c37.633 6.34 67.402 36.113 73.742 73.742zm0 0" />
      <path d="M367.027 62.973C326.422 22.363 272.43 0 215 0S103.578 22.363 62.973 62.973C22.363 103.578 0 157.57 0 215c0 42.563 11.121 84.707 33.055 125.262 17.316 32.011 41.37 63.078 71.496 92.332 50.937 49.469 101.285 76.52 103.402 77.648a14.998 14.998 0 0014.094 0c2.117-1.129 52.465-28.18 103.402-77.648 30.125-29.254 54.18-60.32 71.496-92.332C418.88 299.707 430 257.562 430 215c0-57.43-22.363-111.422-62.973-152.027zM215 370c-85.469 0-155-69.531-155-155S129.531 60 215 60s155 69.531 155 155-69.531 155-155 155zm0 0" />
    </svg>
  );
};

export default SvgLocation;
