import * as React from "react";

const SvgHam = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M208 165c-19.3 0-35 15.7-35 35s15.7 35 35 35 35-15.7 35-35-15.7-35-35-35zm0 0M261.023 335.383c-2.09-.457-4.175-.918-6.261-1.38-31.989-7.07-62.367-13.784-91.762-4.327v19.5c33.695-8.07 66.414-.856 98.238 6.176 31.989 7.07 62.367 13.78 91.762 4.328v-19.485c-31.473 7.54-62.09 1.727-91.926-4.804a.229.229 0 01-.05-.008zm0 0M254.762 384.645c-31.989-7.067-62.371-13.782-91.762-4.325v19.485c10.563-2.532 21.027-3.559 31.402-3.559 22.72 0 44.985 4.922 66.836 9.75 31.989 7.07 62.367 13.785 91.762 4.328v-19.496c-10.563 2.531-21.031 3.567-31.402 3.567-22.72.003-44.985-4.918-66.836-9.75zm0 0" />
      <path d="M256 0C114.613 0 0 114.613 0 256s114.613 256 256 256 256-114.613 256-256S397.387 0 256 0zm177 250c0 27.176-22.11 49.285-49.285 49.285-18.281 0-34.774-10.25-43.25-25.715a48.29 48.29 0 01-3.29-.148c-8.023 14.996-18.87 28.379-31.777 39.379 19.32 2.039 38.114.98 56.54-7.164 4.636-2.051 10-1.617 14.25 1.152A14.994 14.994 0 01383 319.355v101.29c0 5.937-3.504 11.32-8.938 13.718-17.75 7.848-35.238 10.676-52.464 10.676-22.72 0-44.985-4.922-66.836-9.75-35.242-7.785-68.532-15.144-100.7-.926-4.64 2.051-10 1.617-14.25-1.152A14.994 14.994 0 01133 420.645v-50.368V319.355c0-1.382.2-2.734.559-4.027C101.929 289.938 83 251.308 83 210c0-74.437 60.563-135 135-135 32.18 0 63.348 11.512 87.758 32.418 20.945 17.937 35.86 41.824 42.805 68.219l.214.031c8.25-15.816 24.715-26.383 43.508-26.383 27.176 0 49.285 22.11 49.285 49.285 0 12.282-4.476 23.746-12.406 32.606C431.676 237.133 433 243.527 433 250zm0 0" />
      <path d="M286.246 130.207C267.266 113.953 243.027 105 218 105c-57.898 0-105 47.102-105 105 0 36.113 18.617 69.598 48.95 88.781 34.066-8.472 67.132-1.176 99.284 5.93.489.105.973.215 1.457.32 19.461-9.187 35.825-24.375 46.512-43-10.77-9.047-17.633-22.61-17.633-37.746 0-19.21 11.055-35.879 27.133-44.004a105.235 105.235 0 00-32.457-50.074zM208 265c-35.84 0-65-29.16-65-65s29.16-65 65-65 65 29.16 65 65-29.16 65-65 65zm0 0M392.285 179.285c-9.789 0-17.957 7.418-19.004 17.254-.543 5.09-3.64 9.55-8.219 11.836s-10.007 2.078-14.398-.55c-3.133-1.876-6.434-2.825-9.809-2.825-10.632 0-19.285 8.652-19.285 19.285 0 10.637 8.653 19.285 19.285 19.285 1.465 0 3.13-.277 5.235-.875a14.982 14.982 0 014.101-.574 15.01 15.01 0 0114.676 11.898c1.871 8.848 9.797 15.266 18.848 15.266 10.633 0 19.285-8.648 19.285-19.285 0-5.656-2.55-9.848-4.687-12.363a15.005 15.005 0 013.753-22.59c5.954-3.55 9.504-9.707 9.504-16.477 0-10.632-8.648-19.285-19.285-19.285zm0 0" />
    </svg>
  );
};

export default SvgHam;
