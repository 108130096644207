import * as React from "react";

const SvgPieceofcake = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M30 331h331v30H30zm0 0M69.344 241H45c-8.285 0-15 6.715-15 15v45h93.762c-1.68-4.715-2.762-9.715-2.762-15v-15c-22.113 0-41.25-12.152-51.656-30zm0 0M211 271v15c0 5.285-1.082 10.285-2.762 15h35.524c-1.68-4.715-2.762-9.715-2.762-15v-15zm0 0" />
      <path d="M482 421V256c0-8.285-6.715-15-15-15h-84.344c-10.406 17.848-29.543 30-51.656 30v15c0 5.285-1.082 10.285-2.762 15H376c8.29 0 15 6.707 15 15v105zm0 0M30 391h331v30H30zm0 0M45 512h422c24.852 0 45-21.148 45-46 0-8.285-6.715-15-15-15H15c-8.285 0-15 6.715-15 15 0 24.852 20.148 46 45 46zm0 0M121 181c-16.57 0-30 13.43-30 30 0 16.566 13.43 30 30 30h30v45c0 8.273 6.723 15 15 15s15-6.727 15-15v-45h90v45c0 8.273 6.723 15 15 15s15-6.727 15-15v-45h30c16.57 0 30-13.434 30-30 0-16.57-13.43-30-30-30h-21.168c-23.844 19.102-52.875 30.016-83.848 30.016-30.968 0-60-10.914-83.836-30.016zm0 0M300.238 4.395c-5.86-5.86-15.351-5.86-21.21 0L220.265 62.48c2.734-1.16 5.664-1.777 8.68-1.187L330.64 81.637c1.066-27.223-8.864-55.719-30.403-77.242zm0 0" />
      <path d="M151.762 129.027c-5.86 5.86-5.86 15.352 0 21.211 21.949 21.965 51.133 31.782 79.84 30.242L211.293 78.941c-.64-3.21.137-6.324 1.5-9.207zm0 0M300.238 150.238c11.617-11.617 19.57-25.054 24.614-39.168l-79.735-15.953 15.906 79.531c14.286-5.062 27.778-12.972 39.215-24.41zm0 0" />
    </svg>
  );
};

export default SvgPieceofcake;
