import * as React from "react";

const SvgKitchen = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M15 90h111c8.285 0 15-6.715 15-15V15c0-8.285-6.715-15-15-15H15C6.715 0 0 6.715 0 15v60c0 8.285 6.715 15 15 15zm0 0M497 0H386c-8.285 0-15 6.715-15 15v60c0 8.285 6.715 15 15 15h111c8.285 0 15-6.715 15-15V15c0-8.285-6.715-15-15-15zm0 0M121 512h270V302H121zm60-135c0-8.285 6.715-15 15-15h120c8.285 0 15 6.715 15 15v60c0 8.285-6.715 15-15 15H196c-8.285 0-15-6.715-15-15zm0 0" />
      <path d="M211 392h90v30h-90zm0 0M131 215c0 8.285 6.715 15 15 15h220c8.285 0 15-6.715 15-15v-45H131zm0 0M373.21 140L341 99.738V15c0-8.285-6.715-15-15-15H186c-8.285 0-15 6.715-15 15v84.738L138.79 140zm0 0M497 302h-76v60h91v-45c0-8.285-6.715-15-15-15zm0 0M421 512h76c8.285 0 15-6.715 15-15V392h-91zm0 0M0 317v45h91v-60H15c-8.285 0-15 6.715-15 15zm0 0M0 497c0 8.285 6.715 15 15 15h76V392H0zm0 0" />
    </svg>
  );
};

export default SvgKitchen;
