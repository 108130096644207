import * as React from "react";

const SvgCoffee = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="-91 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M330 196v-60c0-8.29-6.71-15-15-15H195c-8.29 0-15 6.71-15 15 0 8.277-6.723 15-15 15s-15-6.723-15-15c0-8.29-6.71-15-15-15H15c-8.29 0-15 6.71-15 15v60c0 8.29 6.71 15 15 15h300c8.29 0 15-6.71 15-15zm0 0M255 0h-60a15.008 15.008 0 00-13.418 8.29L155.727 61H45c-8.29 0-15 6.71-15 15v15h270V76c0-8.29-6.71-15-15-15h-15V15c0-8.29-6.71-15-15-15zm0 0M298.43 241H31.57l10 90H105c14.063-18.691 36.328-30 60-30s45.938 11.309 60 30h63.43zm0 0M285.094 361h-68.121a14.995 14.995 0 01-12.98-7.484C195.952 339.629 181.011 331 165 331s-30.953 8.629-38.992 22.516a14.995 14.995 0 01-12.98 7.484H44.901l3.336 30h64.79a15 15 0 0112.98 7.484C134.047 412.371 148.988 421 165 421s30.953-8.629 38.992-22.516a14.995 14.995 0 0112.98-7.484h64.79zm0 0M165 451c-23.672 0-45.938-11.309-60-30H51.57l8.52 77.656A15 15 0 0075 512h180a15 15 0 0014.91-13.344L278.43 421H225c-14.062 18.691-36.328 30-60 30zm0 0" />
    </svg>
  );
};

export default SvgCoffee;
