import * as React from "react";

const SvgLollipop = (props) => {
  return (
    <svg
      height="511pt"
      viewBox="0 1 511 512"
      width="511pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M160.398 203.59c-14.136-14.137-25.53-33.004-31.394-55.95-10.352 42.5-6.27 87.704 12.242 127.973 32.91 30.961 68.004 38.547 96.816 31.328 40.133-10.035 66.833-45.785 67.79-85.289-38.274 22.61-86.547 22.82-125.555-2.062-6.953-4.422-13.664-9.766-19.899-16zm0 0" />
      <path d="M379.035 301.969c2.57-34.89-14.605-66.446-43.09-83.625.524 54.242-35.734 103.976-90.601 117.695-19.18 4.797-40.489 4.785-62.27-1.535 33.016 31.75 74.473 49.684 117.059 53.598 46.808-13.907 75.836-44.672 78.902-86.133zm0 0M453.504 53.906C420.434 21.953 378.836 3.926 336.105 0c-46.804 13.902-75.835 44.672-78.902 86.129-2.558 34.89 14.61 66.422 43.106 83.617-.633-48.11 27.296-92.719 73.703-112.094 22.898-9.554 50.715-11.968 79.492-3.746zm0 0" />
      <path d="M477.367 306.258c11.438-48.465-1.89-89.817-37.656-112.531-28.41-18.055-63.328-18.403-91.582-2.95 40.02 22.352 64.332 65.54 60.809 113.399-1.926 26.18-13.352 54.351-36.852 77.148 30.742-8.804 59.746-25.219 83.918-49.386 8.031-8.036 15.027-16.696 21.363-25.68zm0 0" />
      <path d="M494.852 112.18C456.273 75.996 415.32 72.918 385.57 85.332c-29.656 12.395-54.78 42.914-55.363 81.223 38.246-22.676 86.547-22.961 125.59 1.847 22.82 14.543 42.89 39.286 51.305 72.598 3.882-15.754 5.48-31.883 5.394-48-.144-27.633-5.824-55.23-17.644-80.82zm0 0M227.3 83.922c1.927-26.18 13.352-54.348 36.852-77.149-30.746 8.805-59.75 25.22-83.918 49.391-8.039 8.035-15.039 16.703-21.379 25.695-11.421 48.461 1.93 89.801 37.567 112.45 28.344 18.062 63.18 18.492 91.433 3.16-9.656-5.41-18.464-11.938-26.093-19.567-23.969-23.968-37.145-57.714-34.461-93.98zm0 0M159.02 353.148a225 225 0 01-28.614-35.027L73.691 375h111.164c-8.984-6.613-17.71-13.727-25.835-21.852zm0 0M170.871 405H43.777l-30.12 30.2c-.532.53-.696 1.25-1.196 1.8h126.496zm0 0M77.297 498.84L109.043 467H.5c-.004 11.531 4.379 23.063 13.156 31.84 17.547 17.547 46.094 17.547 63.64 0zm0 0" />
    </svg>
  );
};

export default SvgLollipop;
