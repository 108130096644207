import * as React from "react";

const SvgIcecream = (props) => {
  return (
    <svg
      height="511pt"
      viewBox="0 -9 511.999 511"
      width="511pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M146.977 177.148a74.76 74.76 0 01-9.532-11.68l-11.68 11.68c-17.546 17.547-17.546 46.094 0 63.641l127.278 127.277c17.547 17.547 46.094 17.547 63.64 0l14.516-14.515c-9.047-4.29-17.648-9.828-25.125-17.305zm0 0M507.602 92.297a14.992 14.992 0 003.253-16.348c-2.3-5.633-7.789-9.258-13.859-9.258-23.016 0-45.547-9.332-61.816-25.605L412.145 18.05c-23.399-23.403-61.457-23.403-84.856 0-7.945 7.941-12.969 17.625-15.52 27.804-17.628-13.253-42.68-12.03-58.726 4.012-17.574 17.574-17.574 46.067 0 63.64l-21.211-21.214c-17.574-17.57-46.066-17.57-63.64 0-17.575 17.574-17.575 46.066 0 63.64l159.097 159.098c23.43 23.434 61.422 23.434 84.856 0 18.574-18.574 22.175-46.203 11.3-68.574 11.395-2.172 22.317-7.473 31.125-16.277 20.86-20.864 23.356-54.434 4.43-79.715l-15.516-15.703c15.704-2.133 30.86-9.207 42.903-21.254zm0 0M7.645 413.5a53.326 53.326 0 008.023 65.266 53.331 53.331 0 0065.27 8.027l153.867-94.54-133.227-133.226zm0 0" />
    </svg>
  );
};

export default SvgIcecream;
