import * as React from "react";

const SvgFish = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 0 512.001 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M510.238 15.473c.348 4.238-1.093 8.593-4.336 11.836l-56.566 56.57a14.955 14.955 0 01-10.606 4.394c-3.84 0-7.68-1.464-10.605-4.394-5.86-5.86-5.86-15.356 0-21.215l56.566-56.566c3.243-3.243 7.602-4.684 11.84-4.336-5.062-.547-45.508-4.559-96.676 1.742-31.652 3.894-60.878 10.957-86.863 20.988-33.015 12.746-60.894 30.352-82.87 52.328-21.977 21.977-39.583 49.86-52.329 82.871-10.035 25.989-17.098 55.215-20.992 86.864-3.832 31.156-3.848 58.336-3.149 75.972l-51.035-12.468c-30.387-7.598-68.941 6.414-98.222 35.691a15.004 15.004 0 00-3.852 14.61 14.999 14.999 0 0010.82 10.546c69.832 17.461 112.27 59.895 129.727 129.73a14.999 14.999 0 0010.55 10.817c1.32.367 2.669.547 4 .547 3.926 0 7.758-1.543 10.61-4.395 29.277-29.28 43.29-67.835 35.71-98.148l-12.491-51.113c5.254.21 11.343.355 18.136.355 16.032 0 35.957-.808 57.836-3.5 31.653-3.894 60.88-10.957 86.864-20.992 33.015-12.746 60.898-30.352 82.875-52.328 21.972-21.977 39.582-49.86 52.328-82.871 3.012-7.801 5.75-15.903 8.222-24.266a15.095 15.095 0 01-2.203 2.824C471.11 199.988 441.66 211.2 412.211 211.2c-29.45 0-58.899-11.21-81.316-33.633-5.86-5.855-5.86-15.351 0-21.21 5.855-5.856 15.355-5.856 21.21 0 33.145 33.14 87.067 33.14 120.211 0 5.856-5.856 15.356-5.856 21.211 0a14.952 14.952 0 014.395 10.628c4.664-17.265 8.207-35.601 10.578-54.84 6.297-51.144 2.293-91.581 1.738-96.671zM375.09 108.629c-5.86 5.855-15.356 5.855-21.211 0-5.86-5.86-5.86-15.356 0-21.215 5.855-5.86 15.351-5.86 21.21 0 5.86 5.86 5.86 15.356 0 21.215zm0 0" />
    </svg>
  );
};

export default SvgFish;
