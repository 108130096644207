import * as React from "react";

const SvgRice = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 -11 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M124.492 475c0 8.285 6.719 15 15 15h233.016c8.285 0 15-6.715 15-15v-30H124.492zm0 0M476.836 127.5c-12.36-21.406-35.11-34.094-58.867-34.469-.375-23.758-13.067-46.511-34.469-58.867-21.406-12.36-47.453-11.969-68.215-.418C303.082 13.36 280.715 0 256 0s-47.082 13.36-59.285 33.746c-20.762-11.555-46.809-11.941-68.215.418-21.402 12.356-34.094 35.11-34.469 58.867-23.758.375-46.511 13.067-58.867 34.469a68.83 68.83 0 00-5.633 12.5H482.47a68.83 68.83 0 00-5.633-12.5zm0 0M497 170H15c-8.285 0-15 6.715-15 15v19c0 84.75 41.98 163.504 111.023 211h289.954C470.02 367.504 512 288.75 512 204v-19c0-8.285-6.715-15-15-15zm0 0" />
    </svg>
  );
};

export default SvgRice;
