import * as React from "react";

const SvgHotPot = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 511.999 511.999"
      {...props}
    >
      <path d="M489.141 91.048c30.497-20.31 30.456-67.733 0-88.016-7.063-4.71-16.555-2.808-21.25 4.256-4.695 7.05-2.794 16.456 4.256 21.151 12.261 8.184 12.312 26.181 0 34.4-30.464 20.289-30.457 67.833-.001 88.116 6.984 4.669 16.529 2.827 21.251-4.256 4.695-7.035 2.794-16.556-4.256-21.251-12.281-8.218-12.281-26.181 0-34.4zM414.258 91.048c30.497-20.31 30.456-67.733 0-88.016a15.29 15.29 0 00-21.251 4.256c-4.695 7.05-2.794 16.456 4.256 21.151 12.261 8.184 12.312 26.181 0 34.4-30.463 20.289-30.456 67.833 0 88.116 6.984 4.669 16.529 2.827 21.251-4.256 4.695-7.035 2.794-16.556-4.256-21.251-12.281-8.218-12.281-26.181 0-34.4zM39.839 91.048c14.319-9.536 22.875-28.209 22.875-45.409S54.158 12.568 39.839 3.033C32.776-1.678 23.284.224 18.589 7.288c-4.695 7.05-2.794 16.456 4.256 21.151 5.85 3.905 9.2 10.165 9.2 17.2s-3.35 13.294-9.201 17.199c-30.463 20.289-30.456 67.833 0 88.116 6.984 4.669 16.529 2.827 21.251-4.256 4.695-7.035 2.794-16.556-4.256-21.251-12.281-8.217-12.281-26.18 0-34.399zM114.723 91.048c30.497-20.31 30.456-67.733 0-88.016-7.063-4.71-16.555-2.808-21.25 4.256-4.695 7.05-2.794 16.456 4.256 21.151 12.261 8.184 12.312 26.181 0 34.4-30.464 20.289-30.457 67.833-.001 88.116 6.984 4.669 16.529 2.827 21.251-4.256 4.695-7.035 2.794-16.556-4.256-21.251-12.281-8.218-12.281-26.181 0-34.4zM329.839 41.632c-1.404-6.991-7.547-12.137-14.684-12.137H195.341c-7.137 0-13.28 5.146-14.684 12.137L159.102 152.11h192.291L329.839 41.632zM494.875 182.064H15.619c-8.278 0-14.977 6.699-14.977 14.977 0 73.711 53.473 133.464 121.178 165.505 38.857 18.388 84.469 29.193 133.427 29.193s94.572-10.805 133.429-29.193c67.336-31.871 121.176-91.612 121.176-165.505 0-8.278-6.698-14.977-14.977-14.977zM407.721 457.803l-13.028-65.147c-41.256 18.402-88.774 29.036-139.445 29.036-50.672 0-98.192-10.631-139.448-29.036l-13.026 65.147c-5.579 27.772 15.726 53.75 44.053 53.75h216.841c28.327-.001 49.632-25.978 44.053-53.75z" />
    </svg>
  );
};

export default SvgHotPot;
