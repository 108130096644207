import * as React from "react";

const SvgMeat = (props) => {
  return (
    <svg
      height="511pt"
      viewBox="1 -72 512 511"
      width="511pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M458.324 53.863C423.828 19.45 377.898.5 329 .5c-51.7 0-101.23 22.027-135.89 60.438-13.508 14.964-32.5 23.55-52.11 23.55-77.75 0-141 63.254-141 141 0 77.746 63.25 141 141 141h186c.137 0 .273-.004.41-.008l.047.004c.512.008 1.023.016 1.543.016 48.96 0 94.973-19.09 129.555-53.754 34.578-34.66 53.558-80.719 53.445-129.687-.117-48.903-19.18-94.782-53.676-129.196zM452 183.496c0 67.762-55.129 122.941-122.887 123.004h-.02c-.073 0-.152 0-.226-.004l-1.398-.02-186.469.012c-44.664 0-81-36.336-81-81s36.336-81 81-81c36.563 0 71.79-15.804 96.652-43.355C260.957 75.313 294.25 60.5 329 60.5c32.902 0 63.781 12.73 86.95 35.84 23.171 23.113 35.972 53.96 36.05 86.86zm0 0" />
      <path d="M329 90.5c-26.273 0-51.45 11.2-69.074 30.73-30.543 33.848-73.89 53.258-118.926 53.258-28.121 0-51 22.88-51 51 0 28.121 22.879 51 51 51l188.203.012c51.18-.11 92.797-41.805 92.797-93.004v-.262c-.059-24.863-9.73-48.195-27.238-65.656C377.258 100.118 353.902 90.5 329 90.5zM142 240.488c-8.285 0-15-6.715-15-15s6.715-15 15-15 15 6.715 15 15-6.715 15-15 15zM329 228.5c-24.813 0-45-20.188-45-45s20.188-45 45-45 45 20.188 45 45-20.188 45-45 45zm0 0" />
      <path d="M329 168.5c-8.273 0-15 6.727-15 15 0 8.27 6.727 15 15 15 8.27 0 15-6.73 15-15 0-8.273-6.73-15-15-15zm0 0" />
    </svg>
  );
};

export default SvgMeat;
