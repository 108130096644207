import * as React from "react";

const SvgCoffeecup = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 -15 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M410 202.047V177c0-8.285-6.715-15-15-15H15c-8.285 0-15 6.715-15 15v159.023c0 55.094 23.324 106.961 63.992 142.297a14.994 14.994 0 009.84 3.68h262.336c3.613 0 7.11-1.305 9.84-3.68 21.05-18.289 37.437-41.015 48.367-66.32H407c57.898 0 105-47.102 105-105 0-56.895-45.484-103.36-102-104.953zM407 382h-2.543A192.963 192.963 0 00410 336.023V232.074c39.969 1.582 72 34.574 72 74.926 0 41.355-33.645 75-75 75zm0 0M90 90h75c13.785 0 25 11.215 25 25 0 8.285 6.715 15 15 15s15-6.715 15-15c0-30.328-24.672-55-55-55H90c-8.27 0-15-6.73-15-15s6.73-15 15-15h185c8.285 0 15-6.715 15-15s-6.715-15-15-15H90C65.187 0 45 20.188 45 45s20.188 45 45 45zm0 0" />
    </svg>
  );
};

export default SvgCoffeecup;
