import * as React from "react";

const SvgOnionring = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 -46 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M489.785 240H22.215L256 387.305zm0 0M263.953 417.715A14.98 14.98 0 01256 420h181a15.005 15.005 0 0013.93-9.434L512 261.395zm0 0M0 261.395l61.07 149.171C63.355 416.266 69.863 420 76 420h180a14.98 14.98 0 01-7.953-2.285zm0 0M331 90c0-8.277-6.723-15-15-15s-15 6.723-15 15 6.723 15 15 15 15-6.723 15-15zm0 0M196 90c0-8.277-6.723-15-15-15s-15 6.723-15 15 6.723 15 15 15 15-6.723 15-15zm0 0" />
      <path d="M391 90c5.086 0 9.93.883 14.848 1.5.007-.512.152-.984.152-1.5 0-49.707-40.293-90-90-90-27.035 0-51.004 12.168-67.5 31.04C232.004 12.167 208.035 0 181 0c-49.707 0-90 40.293-90 90 0 1.414.352 2.73.418 4.133C100.934 91.695 110.734 90 121 90c5.078 0 10.113.344 15.102.996-.008-.34-.102-.652-.102-.996 0-24.813 20.188-45 45-45s45 20.188 45 45c0 1.508-.3 2.934-.445 4.398C235.335 91.816 245.418 90 256 90c5.188 0 10.14.89 15.156 1.531-.02-.52-.156-1.004-.156-1.531 0-24.813 20.188-45 45-45s45 20.188 45 45c0 1.39-.285 2.7-.41 4.059A115.427 115.427 0 01391 90zm0 0M391 120c-16.242 0-31.266 4.645-44.422 12.172 9.766 11.351 17.363 24.41 22.367 38.844 6.547-3.72 14-6.016 22.055-6.016 24.813 0 45 20.188 45 45h45c0-49.707-40.293-90-90-90zm0 0M301 210h45c0-49.707-40.293-90-90-90s-90 40.293-90 90h45c0-24.813 20.188-45 45-45s45 20.188 45 45zm0 0M165.422 132.172C152.266 124.645 137.242 120 121 120c-49.707 0-90 40.293-90 90h45c0-24.813 20.188-45 45-45 8.055 0 15.508 2.297 22.055 6.016 5.004-14.434 12.601-27.493 22.367-38.844zm0 0M391 195c-8.277 0-15 6.723-15 15h30c0-8.277-6.723-15-15-15zm0 0" />
      <path d="M241 210h30c0-8.277-6.723-15-15-15s-15 6.723-15 15zm0 0M106 210h30c0-8.277-6.723-15-15-15s-15 6.723-15 15zm0 0" />
    </svg>
  );
};

export default SvgOnionring;
