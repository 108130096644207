import * as React from "react";

const SvgDelivery = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 -46 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M422 105V45c0-8.29-6.71-15-15-15h-60c-19.531 0-36.023 12.578-42.238 30H255c-8.29 0-15 6.71-15 15s6.71 15 15 15h49.762a44.916 44.916 0 0030.34 28.2C314.238 140.604 302 170.155 302 202.073V270h-66.188l-20.003-60H31.75c-16.168 15.79-26.906 36.79-30.254 60.21C20.453 251.575 46.383 240 75 240h30c57.89 0 105 47.11 105 105v15h152v-62.578c0-34.426 15.027-67 41.234-89.356 3.953-3.37 8.528-5.777 12.852-8.582L396.207 120H407c8.29 0 15-6.71 15-15zm0 0M452 300c-33.137 0-60 26.863-60 60s26.863 60 60 60 60-26.863 60-60-26.863-60-60-60zm0 75c-8.285 0-15-6.715-15-15s6.715-15 15-15 15 6.715 15 15-6.715 15-15 15zm0 0" />
      <path d="M30 180h195c8.285 0 15-6.715 15-15 0-24.852-20.148-45-45-45H30c-16.57 0-30 13.43-30 30 0 16.566 13.43 30 30 30zm0 0M31.52 90h146.96c-5.976-29.324-29.164-52.355-58.48-58.355V15c0-8.29-6.71-15-15-15S90 6.71 90 15v16.645c-29.316 6-52.504 29.03-58.48 58.355zm0 0M15 360h165v-15c0-41.422-33.578-75-75-75H75c-41.422 0-75 33.578-75 75 0 8.285 6.715 15 15 15zm0 0M90 420c22.152 0 41.285-12.14 51.68-30H38.32c10.395 17.86 29.528 30 51.68 30zm0 0M457.027 213.063c-12.754 3.66-24.48 9.425-34.324 17.828-18.371 15.68-29 38.156-30.223 62.12C408.383 278.868 429.09 270 452 270c22.266 0 42.402 8.426 58.133 21.867 1.094-2.086 1.867-4.351 1.867-6.867 0-34.371-23.379-63.11-54.973-71.938zm0 0" />
    </svg>
  );
};

export default SvgDelivery;
