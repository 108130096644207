import * as React from "react";

const SvgSausage = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M467 0c-11.207 0-21.46 4.129-29.348 10.93 13.836 6.148 26.559 14.851 37.575 25.867 11.168 11.168 19.765 23.941 25.816 37.582C507.859 66.489 512 56.223 512 45c0-24.813-20.188-45-45-45zm0 0M10.938 437.645C4.133 445.53 0 455.789 0 467c0 24.813 20.188 45 45 45 11.215 0 21.477-4.133 29.363-10.941-13.824-6.149-26.535-14.848-37.543-25.856-11.02-11.02-19.726-23.738-25.883-37.558zm0 0M319.66 58.012l-10.047 10.047 37.586 75.168c3.703 7.41.7 16.418-6.71 20.125a14.95 14.95 0 01-6.696 1.585c-5.504 0-10.8-3.039-13.426-8.296l-33.113-66.223-27.137 27.137 37.582 75.168c3.707 7.41.703 16.422-6.707 20.125a14.911 14.911 0 01-6.695 1.586c-5.492 0-10.781-3.028-13.414-8.27l-33.125-66.25-27.137 27.14 37.582 75.169c3.707 7.406.703 16.418-6.707 20.12a14.916 14.916 0 01-6.695 1.59c-5.488 0-10.774-3.023-13.406-8.253l-28.317-56.625-4.82-9.64-27.137 27.136 37.586 75.168c3.703 7.41.7 16.418-6.71 20.125a14.963 14.963 0 01-6.696 1.586c-5.485 0-10.766-3.024-13.403-8.25l-.023-.047-33.113-66.223-27.137 27.137 37.586 75.168c3.703 7.406.7 16.418-6.711 20.125a14.963 14.963 0 01-6.695 1.586c-5.504 0-10.797-3.04-13.426-8.293v-.004l-33.113-66.223-31.23 31.23C40.09 337.579 30.21 361.438 30.21 386.813s9.879 49.231 27.824 67.176c18.52 18.52 42.848 27.782 67.176 27.782 24.324 0 48.652-9.262 67.172-27.782L454.016 192.36c37.039-37.043 37.039-97.308 0-134.351-37.043-37.04-97.313-37.035-134.356.004zm-98.61 196.984l.321.64s-.113-.222-.32-.64zm-.628-1.262l.254.516-.254-.516zm50.152-48.18l.274.544c-.04-.078-.133-.266-.274-.543zm0 0" />
    </svg>
  );
};

export default SvgSausage;
