import * as React from "react";

const SvgTakeaway = (props) => {
  return (
    <svg
      height="511pt"
      viewBox="0 0 511 512"
      width="511pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M288.55.094c-8.097-.903-15.898 4.797-17.093 12.898l-11.102 78.086h26.098c1.5 0 2.996 0 4.5.297l10.496-74.184c.903-8.101-4.797-15.898-12.898-17.097zm0 0M381.234 30.988c-8.097-2.699-16.496 1.5-18.894 9.297l-23.695 72.387c9.597 9 16.796 20.695 20.093 33.894l31.793-96.683c2.703-7.801-1.5-16.496-9.297-18.895zm0 0M310.238 452.012H202.68L142.69 512h227.536zm0 0M172.684 271.043H340.23l59.989-59.988H112.695zm0 0" />
      <path d="M3.023 294.36c4.52 6.792 13.786 8.808 20.797 4.16l70.477-46.317 26.48 239.297 65.086-65.09a14.99 14.99 0 0110.606-4.394h119.976a14.99 14.99 0 0110.606 4.394l65.086 65.09 26.48-239.297 70.477 46.317c7.015 4.652 16.277 2.628 20.797-4.16 4.597-6.9 2.738-16.2-4.16-20.797l-75.762-49.844-72.926 72.926a14.987 14.987 0 01-10.602 4.394H166.473a14.98 14.98 0 01-10.602-4.394l-72.926-72.926-75.761 49.844c-6.899 4.597-8.758 13.898-4.16 20.796zm0 0M152.676 152.266c-18.598 3.597-34.793 14.097-45.89 28.793H247.46l12.894-89.98h-33.89c-36.594 0-67.188 26.394-73.79 61.187zm0 0M278.055 181.059h37.793l22.793-68.387c-12.297-12.598-29.094-20.395-47.688-21.297zm0 0M360.238 152.266c-.3-2.102-.898-3.899-1.5-5.7l-11.695 34.493h59.086c-11.098-14.696-27.293-25.196-45.89-28.793zm0 0" />
    </svg>
  );
};

export default SvgTakeaway;
