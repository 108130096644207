import * as React from "react";

const SvgDeliverytruck = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 -58 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M120 60.84C52.594 68.324 0 125.633 0 195v5h120zm0 0M115 275c-33.086 0-60 26.914-60 60s26.914 60 60 60 60-26.914 60-60-26.914-60-60-60zm0 75c-8.27 0-15-6.73-15-15s6.73-15 15-15 15 6.73 15 15-6.73 15-15 15zm0 0M397 275c-33.086 0-60 26.914-60 60s26.914 60 60 60 60-26.914 60-60-26.914-60-60-60zm0 75c-8.27 0-15-6.73-15-15s6.73-15 15-15 15 6.73 15 15-6.73 15-15 15zm0 0" />
      <path d="M497 0H212v350h96.258a90.156 90.156 0 01-1.133-19.734c2.46-46.875 41.09-84.297 88.02-85.246C445.613 243.996 487 284.758 487 335c0 5.11-.434 10.121-1.258 15H497c8.285 0 15-6.715 15-15V15c0-8.285-6.715-15-15-15zm-60 216H257c-8.285 0-15-6.715-15-15s6.715-15 15-15h1.258c6.34-37.633 36.11-67.402 73.742-73.742V101h-5c-8.285 0-15-6.715-15-15s6.715-15 15-15h40c8.285 0 15 6.715 15 15s-6.715 15-15 15h-5v11.258c37.633 6.34 67.402 36.113 73.742 73.742H437c8.285 0 15 6.715 15 15s-6.715 15-15 15zm0 0" />
      <path d="M347 141c-27.906 0-51.426 19.148-58.102 45h116.204c-6.676-25.852-30.196-45-58.102-45zm0 0M150 215c0 8.25-6.75 15-15 15H0v105c0 8.285 6.715 15 15 15h11.258a90.156 90.156 0 01-1.133-19.734c2.46-46.875 41.09-84.297 88.02-85.246 27.324-.555 51.98 11.144 68.855 29.96V60h-32zm0 0" />
    </svg>
  );
};

export default SvgDeliverytruck;
