import * as React from "react";

const SvgPretzel = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M482 120c-16.5 0-30 13.5-30 30 0 47.402-39.098 90-91 90-49.8 0-90-40.2-90-90s40.2-90 90-90c33.898 0 64.602 18.602 79.898 46.5C451.7 96 466.098 90 482 90c6.598 0 12.898 1.2 18.898 3C478.398 38.398 423.7 0 361 0c-82.8 0-150 67.2-150 150s67.2 150 150 150c80.367 0 151-64.781 151-150 0-16.5-13.5-30-30-30zm0 0M0 150c0 85.219 70.633 150 151 150 30 0 57.898-9 81.3-24.3-13.8-14.4-25.5-30.9-34.198-49.5-13.5 8.698-29.7 13.8-47.102 13.8-51.902 0-91-42.598-91-90 0-16.5-13.5-30-30-30S0 133.5 0 150zm0 0M11.102 93c6-1.8 12.3-3 18.898-3 15.902 0 30.3 6 41.102 16.5C86.398 78.602 117.102 60 151 60c17.402 0 33.602 5.098 47.102 13.8C206.8 55.2 218.5 38.7 232.3 24.3 208.898 9 181 0 151 0 88.3 0 33.602 38.398 11.102 93zm0 0" />
      <path d="M443.988 310.05C420.215 390.915 344.562 452 256 452S91.785 390.914 68.012 310.05C40.484 295.89 17.032 274.935 0 249.286V255c0 140.61 115.39 257 256 257s256-116.39 256-257v-5.715c-17.031 25.649-40.484 46.606-68.012 60.766zm0 0" />
    </svg>
  );
};

export default SvgPretzel;
