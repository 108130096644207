import * as React from "react";

const SvgBacon = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 -7 512.001 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M349.344 354.848c-38.172-7.645-67.762-37.235-75.41-75.41-5.243-26.266-25.602-46.625-51.868-51.872-38.175-7.644-67.765-37.234-75.41-75.41-5.23-26.187-49.058-69.078-75.25-74.308-13.976-2.797-26.586-9.22-37.773-17.485L4.395 89.602a14.939 14.939 0 00-4.133 13.347 14.976 14.976 0 008.668 10.973 96.438 96.438 0 0020.043 6.348c26.187 5.222 70.035 48.132 75.254 74.316 7.644 38.18 37.226 67.762 75.406 75.406 26.27 5.262 46.613 25.606 51.87 51.871 3.825 19.09 13.126 36.035 26.25 49.16 13.122 13.125 30.071 22.426 49.157 26.247 26.188 5.222 67.836 46.914 73.098 73.097a96.173 96.173 0 005.586 18.262c1.906 4.578 6.027 7.934 10.926 8.937a15.02 15.02 0 0013.52-4.113l28.628-28.633c-7.852-10.996-13.566-23.5-16.242-36.89-5.227-26.192-46.887-67.852-73.082-73.082zm0 0" />
      <path d="M89.246 4.746L55.082 38.914c6.8 4.34 14.117 7.895 22.215 9.52 38.379 7.66 91.113 59.457 98.773 97.836 5.258 26.265 25.621 46.625 51.883 51.882 38.176 7.633 67.766 37.223 75.395 75.399 5.261 26.265 25.62 46.625 51.886 51.883 38.38 7.664 88.946 58.23 96.606 96.605 1.512 7.54 4.566 14.598 8.508 21.106l34.543-34.543a14.998 14.998 0 003.23-16.383 66.869 66.869 0 01-3.851-12.598c-7.688-38.387-58.235-88.933-96.622-96.621-26.265-5.258-46.609-25.602-51.87-51.871-7.645-38.18-37.227-67.762-75.407-75.406-26.27-5.262-46.613-25.606-51.875-51.871C210.813 65.465 158.098 13.688 119.711 6a65.866 65.866 0 01-13.79-4.36C100.259-.878 93.63.368 89.247 4.747zm0 0M126.309 278.957c-3.059 1.098-6.059 2.336-9.309 2.988-38.367 7.664-88.934 58.23-96.621 96.64a66.36 66.36 0 01-4.36 13.767 14.986 14.986 0 003.11 16.675L53.37 443.27c4.344-6.813 7.824-14.204 9.445-22.286 7.66-38.363 58.227-88.93 96.606-96.62 11.926-2.387 22.539-8.243 31.297-16.208-4.719-4.254-10.442-7.449-16.969-8.754-17.477-3.496-33.45-10.675-47.441-20.445zm0 0M165.313 353.777C139.12 359.023 97.46 400.7 92.23 426.875c-2.8 13.965-8.906 26.832-17.199 38.059l28.95 28.949c3.5 3.5 8.484 5.043 13.351 4.133a14.985 14.985 0 0010.969-8.668 95.208 95.208 0 006.347-20.043c5.243-26.188 46.915-67.856 73.09-73.09 8.64-1.727 16.735-5.031 24.492-8.988-13.234-14.7-22.687-32.196-27.906-51.313-11.414 8.64-24.601 14.984-39.012 17.863zm0 0M406.367 1.16a66.781 66.781 0 01-12.597 3.852c-38.364 7.664-89.93 59.394-97.618 97.781-1.914 9.574-6.312 18.117-11.89 25.742 19.347 5.215 36.61 14.945 51.054 27.75 1.383-3.547 2.504-7.234 3.274-11.07 7.66-38.363 59.226-90.094 97.605-97.781 7.575-1.512 14.5-4.711 20.973-8.63L422.754 4.392a14.998 14.998 0 00-16.387-3.23zm0 0" />
      <path d="M507.605 89.242l-28.789-28.789c-10.933 7.809-23.277 13.707-36.746 16.39-26.18 5.247-68.84 48.083-74.066 74.259a95.813 95.813 0 01-11.324 29.722c8.754 13.242 15.254 28.153 18.515 44.414 1.332 6.645 4.582 12.48 8.957 17.246 13.11-13.136 22.47-29.93 26.27-48.957 5.242-26.183 47.91-69.015 74.098-74.257a96.164 96.164 0 0018.261-5.582 14.97 14.97 0 008.938-10.926 15.027 15.027 0 00-4.114-13.52zm0 0" />
    </svg>
  );
};

export default SvgBacon;
