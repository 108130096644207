import * as React from "react";

const SvgSauces = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="-32 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M328.8 346c0-2.637-.687-5.23-2.007-7.516l-12.992-22.5-13.008 22.532a14.965 14.965 0 00-1.992 7.484c0 8.277 6.722 15 15 15 8.277 0 15-6.723 15-15zm0 0" />
      <path d="M297.406 421h149.82l-19.992-180h-149.82l7.266 65.426 16.129-27.926c5.359-9.285 20.625-9.285 25.984 0l26.004 45.016A45.17 45.17 0 01358.8 346c0 24.813-20.188 45-45 45-7.336 0-14.164-1.93-20.29-5.059zm0 0M238.8 121v24.39c19.009 16.165 32.122 39.133 35.223 65.61h149.395c-3.664-21.66-16.55-39.813-34.617-50.254V121zm0 0M447.602 451h-150c-2.739 23.234-12.844 45.238-28.555 61h107.902c35.567 0 64.961-27.027 70.653-61zm0 0M88.8 15v17.578c0 21.11-8.152 42.578-22.01 58.422h134.022c-13.859-15.844-22.011-37.313-22.011-58.422V15c0-8.29-6.707-15-15-15h-60c-8.29 0-15 6.71-15 15zm0 0M343.8 0h-60c-8.288 0-15 6.71-15 15v17.578c0 21.11-8.152 42.578-22.01 58.422h134.022c-13.859-15.844-22.011-37.313-22.011-58.422V15c0-8.29-6.707-15-15-15zm0 0M.371 421h266.856l-19.993-180H20.364zm94.422-97.457l26.016-45.043c5.359-9.285 20.625-9.285 25.984 0l26.004 45.016A45.17 45.17 0 01178.8 346c0 24.813-20.188 45-45 45s-45-20.188-45-45a45.104 45.104 0 015.992-22.457zm0 0" />
      <path d="M133.8 361c8.278 0 15-6.723 15-15 0-2.637-.687-5.23-2.007-7.516l-12.992-22.5-13.008 22.532a14.965 14.965 0 00-1.992 7.484c0 8.277 6.722 15 15 15zm0 0M58.8 160.695A71.921 71.921 0 0024.07 211h219.461a71.898 71.898 0 00-34.73-50.305V121h-150zm0 0M70.652 512H196.95c35.567 0 64.961-27.027 70.653-61H0c5.691 33.973 35.086 61 70.652 61zm0 0" />
    </svg>
  );
};

export default SvgSauces;
