import * as React from "react";

const SvgHotdog = (props) => {
  return (
    <svg
      height={682.666}
      viewBox="0 0 511.999 511.999"
      width={682.666}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M57.43 263.652L263.652 57.43c8.668-8.668 18.446-15.375 28.61-21.188-39.317-14.937-85.477-6.797-117.09 24.813L61.055 175.172c-31.61 31.61-39.75 77.773-24.813 117.09 5.813-10.164 12.52-19.942 21.188-28.61zm0 0M475.758 219.738c-5.813 10.164-12.52 19.942-21.188 28.61L248.348 454.57c-8.668 8.668-18.446 15.375-28.61 21.188 39.313 14.937 85.477 6.797 117.086-24.813l114.121-114.12c31.61-31.61 39.75-77.774 24.813-117.087zm0 0M68.035 507.602a15.022 15.022 0 0013.012 4.207 14.984 14.984 0 0011.008-8.114l9.812-19.62c-16.176-6.579-31.332-16.403-44.437-29.505-13.102-13.105-22.926-28.261-29.504-44.437L8.3 419.94a15.002 15.002 0 00-8.11 11.012 15.01 15.01 0 004.207 13.008zm0 0M484.074 101.867l19.621-9.812a14.984 14.984 0 008.114-11.008 15.022 15.022 0 00-4.207-13.012L443.96 4.398A15.01 15.01 0 00430.953.191a15.002 15.002 0 00-11.012 8.11l-9.808 19.625c16.176 6.578 31.332 16.402 44.437 29.504 13.102 13.105 22.926 28.261 29.504 44.437zm0 0M78.64 284.863c-40.933 40.938-40.933 107.559 0 148.492 40.938 40.934 107.56 40.934 148.493 0l206.222-206.222c40.934-40.934 40.934-107.555 0-148.492-40.933-40.934-107.554-40.934-148.492 0zm276.747-180.039c24.504-3.375 47.207 13.817 50.687 38.203 5.203 25.934-3.074 53.371-22.277 72.575-16.324 16.324-38.555 24.757-61.207 23.648 1.11 22.676-7.32 44.883-23.649 61.207-16.32 16.324-38.55 24.754-61.203 23.648 1.11 22.672-7.32 44.88-23.648 61.204-19.211 19.214-46.59 27.492-73.219 22.156-24.363-4.86-40.191-28.617-35.281-52.973 4.137-20.676 21.66-34.734 40.969-36.055-1.329-22.87 7.113-45.398 23.613-61.898 16.328-16.324 38.543-24.766 61.195-23.656-1.105-22.633 7.324-44.863 23.656-61.2 16.399-16.394 38.762-24.835 61.528-23.636.633-21.555 16.781-40.188 38.836-43.223zm0 0" />
      <path d="M237.5 263.79a50.948 50.948 0 00-46.113 13.96c-12.098 12.102-17.32 29.336-13.953 46.125 1.39 7.043-.715 13.953-5.77 19.008s-11.965 7.16-18.953 5.777a14.958 14.958 0 00-17.703 11.738c-1.586 7.797 3.84 16.086 11.734 17.637a50.893 50.893 0 0046.133-13.941 50.95 50.95 0 0013.965-46.114c-1.41-7 .683-13.94 5.758-19.015 5.054-5.055 11.976-7.168 18.988-5.77 16.809 3.364 34.047-1.855 46.144-13.953a50.948 50.948 0 0013.961-46.113c-1.41-7 .684-13.942 5.762-19.016 5.055-5.058 11.973-7.172 18.985-5.77 16.812 3.364 34.046-1.855 46.144-13.952 12.098-12.098 17.309-29.41 13.93-46.313-1.29-8.894-8.828-14.691-17.035-13.535-8.184 1.117-13.934 8.707-12.805 16.902 1.855 9.766-.25 16.676-5.305 21.73-5.062 5.067-11.992 7.169-19.015 5.759a50.95 50.95 0 00-46.114 13.964 50.92 50.92 0 00-13.953 46.122c1.39 7.046-.715 13.953-5.77 19.007-5.062 5.067-11.992 7.168-19.015 5.762zm0 0" />
    </svg>
  );
};

export default SvgHotdog;
