import * as React from "react";

const SvgTeapot = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 0 511 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M132.172 259.18l1.187 1.183a232.841 232.841 0 0119.782-19.011C146.176 223.496 142.5 204.367 142.5 185c0-85.469 69.531-155 155-155s155 69.531 155 155c0 19.367-3.676 38.492-10.637 56.348a233.213 233.213 0 0120.54 19.843l1.054 1.059.469.625c.297.332.582.676.879 1.008C476.352 239.359 482.5 212.379 482.5 185c0-102.008-82.988-185-185-185s-185 82.992-185 185c0 23.715 4.61 47.133 13.348 68.918a45.56 45.56 0 016.324 5.262zm0 0" />
      <path d="M341.324 190.762c-1.879-8.106-5.972-15.551-12.004-21.582-8.5-8.5-19.8-13.18-31.82-13.18-21.29 0-39.16 14.867-43.809 34.758C268.152 187.62 282.832 186 297.5 186c14.672 0 29.36 1.625 43.824 4.762zm0 0M419.832 262c-35.156-29.148-79.203-46-122.332-46-43.133 0-87.176 16.852-122.332 46zm0 0M449.316 292H145.711a235.269 235.269 0 00-9.91 13.234l-24.84-24.84A14.986 14.986 0 00100.355 276H15.5a14.998 14.998 0 00-10.605 25.605l62.48 62.48c16.848 16.849 24.54 41.145 20.578 64.993C84.332 450.855 82.5 473.703 82.5 497c0 3.98 1.582 7.793 4.395 10.605A14.986 14.986 0 0097.5 512h400c8.285 0 15-6.715 15-15 0-82.117-22.348-154.293-63.184-205zm0 0" />
    </svg>
  );
};

export default SvgTeapot;
