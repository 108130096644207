import * as React from "react";

const SvgWaterjug = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M482 90h-70v70h30v125c0 19.328 15.668 35 35 35 19.328 0 35-15.672 35-35V120c0-16.57-13.43-30-30-30zm0 0M367 0H15A14.998 14.998 0 002.52 23.32L46.973 90H382V15c0-8.285-6.715-15-15-15zm0 0M75 120H60v30h75c8.285 0 15 6.715 15 15s-6.715 15-15 15H60v30h75c8.285 0 15 6.715 15 15s-6.715 15-15 15H60v30h75c8.285 0 15 6.715 15 15s-6.715 15-15 15H60v30h75c8.285 0 15 6.715 15 15s-6.715 15-15 15H60v30h75c8.285 0 15 6.715 15 15s-6.715 15-15 15H60v77c0 8.285 6.715 15 15 15h292c8.285 0 15-6.715 15-15V120zm0 0" />
    </svg>
  );
};

export default SvgWaterjug;
