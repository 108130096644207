import * as React from "react";

const SvgWrap = (props) => {
  return (
    <svg
      height={512}
      width={512}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      {...props}
    >
      <path d="M292.042 81.5c0 2.447.229 4.836.659 7.149 16.779-14.141 34.927-26.221 54.156-35.979-5.756-4.801-12.977-7.67-20.815-7.67-18.748 0-34 16.374-34 36.5zM209.925 81.05C223.357 71.608 239.294 66.5 256 66.5c2.548 0 5.092.128 7.622.367 4.922-22.676 21.021-40.992 41.942-48.36C292.058 6.751 274.581 0 256.001 0c-24.002 0-46.157 11.25-60.294 30.015-15.843-2.907-32.385.431-46.104 9.647a62.552 62.552 0 00-6.253 4.8 44.715 44.715 0 0112.442 3.658c19.121 8.799 37.255 19.86 54.133 32.93zM263.844 97.127a50.057 50.057 0 00-30.058 4.543 306.53 306.53 0 0122.209 23.773A308.09 308.09 0 01268.8 111.16a67.677 67.677 0 01-4.956-14.033zM258.836 171.783c-14.387 21.866-25.934 45.774-34.408 71.014l.812.528 6.231-4.051c7.319-4.759 15.801-7.273 24.53-7.273s17.21 2.515 24.529 7.273l6.231 4.052 6.229-4.051a44.891 44.891 0 0124.53-7.274 44.898 44.898 0 0124.527 7.271l6.233 4.053 6.229-4.051a44.901 44.901 0 0124.531-7.273c3.704 0 7.395.466 10.976 1.366V89a14.999 14.999 0 00-21.27-13.626c-43.606 20.067-81.613 53.405-109.91 96.409zM386.195 263.816a14.998 14.998 0 00-15.33.609l-22.583 14.684-22.584-14.684a15.002 15.002 0 00-16.354 0l-22.583 14.684-22.584-14.684a15.002 15.002 0 00-16.353 0l-22.584 14.684-22.584-14.684a15.002 15.002 0 00-16.353 0l-22.584 14.684-22.584-14.684a15.002 15.002 0 00-23.176 12.576v160c0 41.355 33.645 75 75 75h126.083c41.354 0 74.999-33.645 74.999-75V277a14.999 14.999 0 00-7.846-13.184zM157.487 239.272l6.232 4.052 6.231-4.052a44.895 44.895 0 0124.529-7.273c.641 0 1.281.018 1.919.045 9.256-27.24 21.796-53.074 37.376-76.752 1.171-1.78 2.362-3.54 3.564-5.289-26.028-32.54-58.152-58.094-94.086-74.63a15 15 0 00-21.271 13.626v144.367a44.91 44.91 0 0135.506 5.906z" />
    </svg>
  );
};

export default SvgWrap;
