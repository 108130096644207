import * as React from "react";

const SvgInstantnoodles = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M497 301H158.031C172.301 285.062 181 264.027 181 241c0-49.629-40.371-90-90-90-33.09 0-60 26.91-60 60s26.91 60 60 60c8.29 0 15-6.71 15-15s-6.71-15-15-15c-16.54 0-30-13.46-30-30s13.46-30 30-30c33.09 0 60 26.91 60 60s-26.91 60-60 60H15c-8.29 0-15 6.71-15 15s6.71 15 15 15h15v60h126.352c14.191-20.469 56.828-30 99.648-30s85.453 9.531 99.648 30H482v-60h15c8.29 0 15-6.71 15-15s-6.71-15-15-15zm0 0M256 451c-42.82 0-85.453-9.531-99.648-30H31.516c7.355 50.75 51.73 91 104.484 91h240c52.754 0 97.129-40.25 104.484-91H355.648c-14.195 20.469-56.828 30-99.648 30zm0 0M329.637 406c-7.043-5.55-32.184-15-73.637-15s-66.594 9.45-73.637 15c7.043 5.55 32.184 15 73.637 15s66.594-9.45 73.637-15zm0 0" />
      <path d="M512 15c0-8.29-6.71-15-15-15H301.945c7.735 9.625 13.973 20.355 17.586 32.46 8.215 27.513 3.809 56.981-12.117 80.86-14.851 22.282-14.851 52.078 0 74.36 16.695 25.043 20.328 55.707 11.207 83.32h60c9.121-27.613 5.488-58.281-11.207-83.32-14.851-22.282-14.851-52.078 0-74.36 4.899-7.347 8.531-15.218 11.207-23.32H497c8.29 0 15-6.71 15-15s-6.71-15-15-15H383.61c0-10.14-1.766-20.242-4.989-30H497c8.29 0 15-6.71 15-15zm0 0" />
      <path d="M181 75c0 8.29 6.71 14.988 15 14.988h29.602c-1.133 2.203-1.747 4.582-3.149 6.684-21.59 32.387-21.59 75.262 0 107.648 13.457 20.184 14.07 45.489 3.149 66.68h60c10.921-21.191 10.308-46.496-3.149-66.68-21.59-32.386-21.59-75.254 0-107.64 10.957-16.438 13.992-36.707 8.336-55.621C285.504 23.348 272.895 9.164 255.49 0H196c-8.29 0-15 6.71-15 15s6.71 15 15 15h29.602c4.894 9.492 7.996 19.637 7.996 30H196c-8.29 0-15 6.71-15 15zm0 0" />
    </svg>
  );
};

export default SvgInstantnoodles;
