import * as React from "react";

const SvgHottea = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="-36 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M395 200h-35v-25c0-8.285-6.715-15-15-15H150v67h25c16.543 0 30 13.457 30 30v120c0 16.543-13.457 30-30 30H95c-16.543 0-30-13.457-30-30V257c0-16.543 13.457-30 30-30h25v-67H15c-8.285 0-15 6.715-15 15v37.227a557.66 557.66 0 007.566 91.406l32.637 195.832A15.002 15.002 0 0055 512h250c7.332 0 13.59-5.3 14.797-12.535L331.375 430H395c24.813 0 45-20.188 45-45V245c0-24.813-20.188-45-45-45zm15 185c0 8.27-6.73 15-15 15h-58.625l16.063-96.367A558.494 558.494 0 00359.71 230H395c8.27 0 15 6.73 15 15zm0 0M65 90h75c13.785 0 25 11.215 25 25 0 8.285 6.715 15 15 15s15-6.715 15-15c0-30.328-24.672-55-55-55H65c-8.27 0-15-6.73-15-15s6.73-15 15-15h185c8.285 0 15-6.715 15-15s-6.715-15-15-15H65C40.187 0 20 20.188 20 45s20.188 45 45 45zm0 0" />
      <path d="M95 377h80.02L175 257H95zm0 0" />
    </svg>
  );
};

export default SvgHottea;
