import * as React from "react";

const SvgBuffet = (props) => {
  return (
    <svg
      height={512}
      width={512}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      {...props}
    >
      <path d="M502.688 398.371a15 15 0 009.313-13.88V226c0-8.284-6.716-15-15-15H15c-8.284 0-15 6.716-15 15v157.895a15 15 0 009.591 13.991C24.72 403.735 32.639 415.04 61 419.452V451H15c-8.284 0-15 6.716-15 15s6.716 15 15 15h482c8.284 0 15-6.716 15-15s-6.716-15-15-15h-46v-31.319c28.285-3.958 37.89-15.656 51.688-21.31zM91 451v-31.468c13.789-2.073 27.184-7.062 39.069-14.985 22.281-14.855 51.076-14.855 73.359 0 32.383 21.59 74.245 21.598 106.641 0 22.282-14.855 51.077-14.855 73.359 0 11.456 7.638 24.312 12.542 37.571 14.744V451zM481 166c0-52.807-39.181-96.624-90-103.924V46c0-8.284-6.716-15-15-15s-15 6.716-15 15v16.076c-50.819 7.301-90 51.117-90 103.924v15h210zM241 166c0-52.807-39.181-96.624-90-103.924V46c0-8.284-6.716-15-15-15s-15 6.716-15 15v16.076C70.181 69.376 31 113.193 31 166v15h210z" />
    </svg>
  );
};

export default SvgBuffet;
