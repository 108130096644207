import * as React from "react";

const SvgOrder = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M347 48h-32.207C309.219 20.645 284.977 0 256 0s-53.219 20.645-58.793 48h31.305c4.64-10.586 15.21-18 27.488-18s22.848 7.414 27.488 18h-54.976A29.852 29.852 0 00226 60c0 16.543 13.457 30 30 30 8.285 0 15 6.715 15 15s-6.715 15-15 15c-33.086 0-60-26.914-60-60 0-4.11.418-8.121 1.207-12h-32.414C159.219 20.645 134.977 0 106 0S52.781 20.645 47.207 48h31.305c4.64-10.586 15.21-18 27.488-18s22.848 7.414 27.488 18H78.512A29.852 29.852 0 0076 60c0 16.543 13.457 30 30 30 8.285 0 15 6.715 15 15s-6.715 15-15 15c-33.086 0-60-26.914-60-60 0-4.11.418-8.121 1.207-12H15C6.715 48 0 54.715 0 63v434c0 8.285 6.715 15 15 15h332c8.285 0 15-6.715 15-15V63c0-8.285-6.715-15-15-15zM75 182h90c8.285 0 15 6.715 15 15s-6.715 15-15 15H75c-8.285 0-15-6.715-15-15s6.715-15 15-15zm212 210H75c-8.285 0-15-6.715-15-15s6.715-15 15-15h212c8.285 0 15 6.715 15 15s-6.715 15-15 15zm0-60H75c-8.285 0-15-6.715-15-15s6.715-15 15-15h212c8.285 0 15 6.715 15 15s-6.715 15-15 15zm0-60H75c-8.285 0-15-6.715-15-15s6.715-15 15-15h212c8.285 0 15 6.715 15 15s-6.715 15-15 15zm0 0M392 215v141c0 19.3 15.7 35 35 35h50c19.3 0 35-15.7 35-35V215zm0 0M505.383 185l-32.281-52.473C468.727 125.418 460.64 121 452 121s-16.727 4.418-21.102 11.527L398.618 185zm0 0" />
    </svg>
  );
};

export default SvgOrder;
