import * as React from "react";

const SvgPizzaslice = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="-16 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M195.004 135c0-4.246-1.149-8.21-2.813-11.977-19.488 2.356-38.851 5.989-57.058 10.778-.016.41-.13.789-.13 1.199 0 16.54 13.462 30 30 30 16.536 0 30-13.46 30-30zm0 0" />
      <path d="M434.988 162.262c-11.707 0-22.968-2.653-33.472-7.899-8.278-4.133-17.579-7.883-27.32-11.39C370.41 163.98 352.085 180 330.003 180c-24.816 0-45-20.188-45-45v-12.352c-15.149-1.726-30.32-2.691-45.016-2.691-5.593 0-11.293.234-16.996.492 1.196 4.754 2.012 9.61 2.012 14.551 0 33.094-26.91 60-60 60-30.367 0-55.254-22.734-59.195-52.035-9.723 3.504-19.004 7.246-27.262 11.37-10.078 5.04-21.461 7.724-32.914 7.724-.723 0-1.395-.278-2.117-.297l31.488 58.449V375c0 24.813 20.184 45 45 45 21.312 0 39.195-14.883 43.84-34.805l62.945 118.91A15.008 15.008 0 00240.004 512c5.52 0 10.59-3.031 13.21-7.895l45.063-85.695c-4.285.973-8.699 1.59-13.273 1.59-33.14 0-60-26.863-60-60s26.86-60 60-60c25.726 0 47.492 16.273 56.02 39.023L436.3 162.078c-.45.008-.86.184-1.313.184zM210.004 285c-24.817 0-45-20.188-45-45 0-8.29 6.707-15 15-15 8.289 0 15 6.71 15 15 0 8.277 6.723 15 15 15 8.273 0 15-6.723 15-15 0-8.29 6.707-15 15-15 8.289 0 15 6.71 15 15 0 24.813-20.188 45-45 45zm90-45c-8.285 0-15-6.715-15-15s6.715-15 15-15c8.281 0 15 6.715 15 15s-6.719 15-15 15zm0 0" />
      <path d="M285.004 330c-16.54 0-30 13.46-30 30s13.46 30 30 30c16.535 0 30-13.46 30-30s-13.465-30-30-30zm0 0M475.258 107.371c11.086-22.176 2.062-49.262-20.13-60.379C397.165 18.004 314.755 0 240.048 0 165.297 0 82.855 18.004 24.89 46.992 2.684 58.11-6.355 85.18 4.75 107.387c10.852 21.605 38.64 30.996 60.379 20.113 45.629-22.809 114.258-37.543 174.86-37.543 24.39 0 50.042 2.48 75.015 6.82V135c0 8.285 6.715 15 15 15 8.281 0 15-6.715 15-15v-31.93c25.89 6.313 50.098 14.555 69.871 24.446 22.082 10.988 49.098 2.379 60.383-20.145zm0 0" />
    </svg>
  );
};

export default SvgPizzaslice;
