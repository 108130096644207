import * as React from "react";

const SvgSandwiches = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 -31 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M377 300H136c-59.914 0-111.238-39.488-128.832-93.613C2.848 217.012 0 228.27 0 240c0 49.629 40.371 90 90 90h332c49.629 0 90-40.371 90-90 0-11.73-2.848-22.988-7.168-33.613C487.238 260.512 436.914 300 377 300zm0 0M9.77 328.594C26.387 364.719 62.703 390 105 390c27.508 0 53.938-11.305 73.27-30H90c-30.95 0-58.926-12.098-80.23-31.406zm0 0M502.23 328.594C480.926 347.902 452.95 360 422 360h-88.27c19.332 18.695 45.762 30 73.27 30 42.297 0 78.613-25.281 95.23-61.406zm0 0M328.793 360H183.207c19.188 18.594 44.926 30 72.793 30s53.605-11.406 72.793-30zm0 0M105 420c-18.656 0-36.434-3.813-52.613-10.688C71.757 434.024 102.757 450 136 450h241c33.242 0 63.242-15.977 82.613-40.688C443.433 416.188 425.656 420 407 420c-27.23 0-54.21-8.277-76.117-22.98C309.156 411.54 283.32 420 256 420s-53.156-8.46-74.883-22.977C159.207 411.723 132.23 420 105 420zm0 0" />
      <path d="M136 60C78.11 60 30 107.11 30 165s48.11 105 106 105h241c57.89 0 105-47.11 105-105S434.89 60 377 60c-41.352 0-76 33.648-76 75 0 8.29-6.71 15-15 15s-15-6.71-15-15c0-25.434 9.457-48.469 24.563-66.66-7.852-3.297-16.13-5.344-24.563-6.707V15c0-8.29-6.71-15-15-15s-15 6.71-15 15v46.52c-34.191 6.968-60 37.265-60 73.48 0 8.29-6.71 15-15 15s-15-6.71-15-15c0-25.434 9.457-48.469 24.563-66.66C163.101 63.11 149.788 60 136 60zm0 0" />
    </svg>
  );
};

export default SvgSandwiches;
