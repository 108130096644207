import * as React from "react";

const SvgIcecoffee = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="-91 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M266.398 30H315c8.402 0 15-6.598 15-15s-6.598-15-15-15h-60c-6.598 0-12.598 4.5-14.398 10.8l-17.704 62.4c9.301 4.198 18.301 9.902 26.403 16.5zm0 0M120 195v8.32a15.03 15.03 0 01-7.457 12.965c-9.488 5.52-16.102 13.973-19.621 23.715h144.32c-6.203-17.434-22.703-30-42.242-30-24.813 0-45-20.188-45-45v-12.438c-17.46 6.2-30 22.883-30 42.438zm0 0" />
      <path d="M15 240h46.8c3.598-17.7 13.5-33.602 28.2-44.7v-.3c0-41.398 33.602-75 75-75 8.402 0 15 6.598 15 15v30c0 7.5 5.098 13.5 12.3 14.7l30.598-106.5C205.2 64.8 185.7 60 165 60 94.8 60 36.898 113.7 30.602 182.402 13.8 188.102 1.5 203.402.3 222 0 222.898 0 223.8 0 225c0 8.402 6.598 15 15 15zm0 0M268.5 240H315c8.402 0 15-6.598 15-15 0-1.2 0-2.102-.3-3-1.2-18.598-13.5-33.898-30.302-39.598-3.296-37.5-22.199-70.504-50.097-92.703l-27.297 95.403C245.699 194.098 263.402 214.8 268.5 240zm0 0M298.238 270H31.762l9.375 76H105c14.062-18.691 36.328-30 60-30s45.937 11.309 60 30h63.863zm0 0M285.113 376h-68.14a14.995 14.995 0 01-12.98-7.484C195.952 354.629 181.011 346 165 346s-30.953 8.629-38.992 22.516a14.995 14.995 0 01-12.98 7.484H44.882l3.75 30h64.394a15 15 0 0112.98 7.484C134.048 427.371 148.989 436 165 436s30.953-8.629 38.992-22.516a14.995 14.995 0 0112.98-7.484h64.395zm0 0M165 466c-23.672 0-45.938-11.309-60-30H52.387l7.73 62.86C61.055 506.36 67.441 512 75 512h180c7.559 0 13.945-5.64 14.883-13.14l7.734-62.86H225c-14.063 18.691-36.328 30-60 30zm0 0" />
    </svg>
  );
};

export default SvgIcecoffee;
