import * as React from "react";

const SvgSandwich = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M226 151c-8.277 0-15 6.723-15 15s6.723 15 15 15 15-6.723 15-15-6.723-15-15-15zm0 0M271 301c-16.54 0-30 13.46-30 30s13.46 30 30 30 30-13.46 30-30-13.46-30-30-30zm0 0M181 271c-16.54 0-30 13.46-30 30s13.46 30 30 30 30-13.46 30-30-13.46-30-30-30zm0 0" />
      <path d="M329.96 192.16c7.927-58.668-42.917-109.7-104.05-99.93-39.738 6.446-71.101 40.415-74.574 80.786-2.2 25.625 6.8 50.062 24.078 68.547 1.867-.176 3.672-.563 5.586-.563 26.16 0 48.219 16.918 56.41 40.309A59.68 59.68 0 01271 271c30.516 0 55.492 22.965 59.238 52.48 13.543-7.44 24.274-20.246 28.39-35.312 5.977-21.77.294-44.02-15.222-59.504-10.297-10.266-15.187-23.566-13.445-36.504zM226 211c-24.813 0-45-20.188-45-45s20.188-45 45-45 45 20.188 45 45-20.188 45-45 45zm0 0" />
      <path d="M437 0H75C33.648 0 0 34.648 0 76c0 36.227 25.813 66.52 60 73.492V437c0 8.29 6.71 15 15 15h362c8.29 0 15-6.71 15-15V149.492c34.188-6.972 60-37.265 60-73.492 0-41.352-33.648-76-75-76zm-49.43 296.105c-7.996 29.18-30.89 52.547-59.765 60.997-1.176.34-2.301.5-3.461.793C314.44 377.44 294.37 391 271 391c-26.156 0-48.219-16.918-56.41-40.312A59.654 59.654 0 01181 361c-33.09 0-60-26.91-60-60 0-19.855 9.797-37.348 24.695-48.273-17.793-23.372-26.816-52.215-24.242-82.274 4.629-53.879 46.54-99.23 99.652-107.844 80.442-12.918 149.282 54.305 138.59 133.567-.48 3.515 1.348 7.719 4.895 11.25 23.262 23.219 31.844 56.367 22.98 88.68zm0 0" />
      <path d="M482 219.79v72.42l25.605-25.605c5.86-5.859 5.86-15.351 0-21.21zm0 0M266.605 507.605L292.211 482h-72.422l25.606 25.605A14.948 14.948 0 00256 512c3.836 0 7.676-1.465 10.605-4.395zm0 0M30 292.21v-72.42L4.395 245.394c-5.86 5.859-5.86 15.351 0 21.21zm0 0" />
    </svg>
  );
};

export default SvgSandwich;
