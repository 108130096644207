import * as React from "react";

const SvgBbq = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M211 90h80c13.785 0 25 11.215 25 25s-11.215 25-25 25h-50c-8.285 0-15 6.715-15 15s6.715 15 15 15h50c30.328 0 55-24.672 55-55s-24.672-55-55-55h-80c-8.27 0-15-6.73-15-15s6.73-15 15-15h40c8.285 0 15-6.715 15-15s-6.715-15-15-15h-40c-24.813 0-45 20.188-45 45s20.188 45 45 45zm0 0M497 230h-36.55c.359-4.953.55-9.953.55-15 0-8.285-6.715-15-15-15H66c-8.285 0-15 6.715-15 15 0 5.047.191 10.047.55 15H15c-8.285 0-15 6.715-15 15s6.715 15 15 15h40.988C76.555 351.46 158.418 420 256 420s179.445-68.54 200.012-160H497c8.285 0 15-6.715 15-15s-6.715-15-15-15zm0 0M241 449.508V497c0 8.285 6.715 15 15 15s15-6.715 15-15v-47.492c-4.96.312-9.96.492-15 .492s-10.04-.18-15-.492zm0 0M103.012 489.496c-4.145 7.176-1.688 16.352 5.488 20.492a14.938 14.938 0 007.484 2.012c5.188 0 10.23-2.691 13.004-7.504l40.899-70.836a234.17 234.17 0 01-27.219-12.851zm0 0M369.332 420.809a234.17 234.17 0 01-27.219 12.851l40.899 70.836c2.777 4.817 7.816 7.504 13.004 7.504 2.543 0 5.12-.648 7.484-2.012 7.176-4.14 9.633-13.316 5.488-20.492zm0 0" />
    </svg>
  );
};

export default SvgBbq;
