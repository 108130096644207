import * as React from "react";

const SvgNoodles = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 -6 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M124.492 485c0 8.285 6.719 15 15 15h233.016c8.285 0 15-6.715 15-15v-30H124.492zm0 0M497 180H232v-60.797l263.824 20.75c.399.031.797.047 1.192.047 7.75 0 14.32-5.965 14.937-13.824.649-8.262-5.52-15.48-13.777-16.13L232 89.11V45h265c8.285 0 15-6.715 15-15s-6.715-15-15-15H232c0-8.285-6.715-15-15-15s-15 6.715-15 15h-30c0-8.285-6.715-15-15-15s-15 6.715-15 15h-30c0-8.285-6.715-15-15-15S82 6.715 82 15H52c-8.285 0-15 6.715-15 15s6.715 15 15 15h30v32.313l-28.824-2.266c-8.23-.656-15.48 5.52-16.13 13.777-.651 8.258 5.52 15.48 13.778 16.13L82 107.405V180H15c-8.285 0-15 6.715-15 15v19c0 84.75 41.98 163.504 111.023 211h289.954C470.02 377.504 512 298.75 512 214v-19c0-8.285-6.715-15-15-15zm-295 0h-30v-65.516l30 2.36zm0-135v41.754l-30-2.363V45zm-90 0h30v37.031l-30-2.36zm0 64.766l30 2.359V180h-30zm0 0" />
    </svg>
  );
};

export default SvgNoodles;
