import * as React from "react";

const SvgSpaghettialt = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 -16 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M271 390h-30c0-49.621-40.434-90-90-90-49.8 0-90 40.2-90 90H31c0-66.3 53.7-120 120-120s120 53.7 120 120zm0 0M46 120c-8.29 0-15 6.71-15 15s6.71 15 15 15h15v120.766c17.473-13.227 37.879-22.602 60-27.286V0H46c-8.29 0-15 6.71-15 15s6.71 15 15 15h15v30H46c-8.29 0-15 6.71-15 15s6.71 15 15 15h15v30zm0 0M497 420H15c-8.285 0-15 6.715-15 15 0 24.852 20.148 45 45 45h422c24.852 0 45-20.148 45-45 0-8.285-6.715-15-15-15zm0 0M481 45c0-8.29-6.71-15-15-15H262.21L236.606 4.395A14.99 14.99 0 00226 0h-75v30h30v30h-30v30h30v30h-30v30h75a14.99 14.99 0 0010.605-4.395L262.211 120H466c8.29 0 15-6.71 15-15zm0 0" />
      <path d="M151 360c16.5 0 30 13.5 30 30h30c0-33-27-60-60-60s-60 27-60 60h30c0-16.5 13.5-30 30-30zm0 0M301 210c-40.73 0-78.203 13.738-108.39 36.605 11.796 3.438 22.94 8.172 33.35 14.243C248.114 247.938 273.528 240 301 240c82.8 0 150 67.2 150 150h30c0-99.043-80.266-180-180-180zm0 0" />
      <path d="M301 300c49.5 0 90 40.5 90 90h30c0-66.3-53.7-120-120-120-17.262 0-33.578 3.785-48.402 10.34 7.937 7.36 15.238 15.36 21.441 24.27 8.57-2.731 17.516-4.61 26.961-4.61zm0 0" />
      <path d="M301 330c-4.129 0-8.156.422-12.05 1.219 3.995 9.347 7.038 19.18 9.077 29.383 1.028-.11 1.918-.602 2.973-.602 16.57 0 30 13.43 30 30h30c0-33.09-26.91-60-60-60zm0 0" />
    </svg>
  );
};

export default SvgSpaghettialt;
