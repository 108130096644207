import * as React from "react";

const SvgChickenbucket = (props) => {
  return (
    <svg
      height="511pt"
      viewBox="1 0 511.999 511"
      width="511pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M63.742 219.828c5.211 6.508 9.54 13.895 13.434 21.664h46.11c0-27.48 6.78-54.168 19.394-77.312-4.871-2.887-10.032-5.457-14.356-8.938 7.602-16.594 4.57-36.91-9.066-50.562-16.977-16.977-46.653-17.008-63.645 0-8.144 8.144-12.508 18.644-13.093 29.324-10.68.586-21.184 4.953-29.329 13.098-17.617 17.617-17.558 46.097 0 63.628 13.668 13.653 33.97 16.7 50.551 9.098zm0 0M436.992 271.488H75.008c-8.293 0-15 6.711-15 15 0 8.293 6.707 15 15 15h16.984l4.285 30h319.446l4.285-30h16.984c8.293 0 15-6.707 15-15 0-8.289-6.707-15-15-15zm0 0M411.438 361.484H100.563l8.57 60h293.734zm0 0M135.004 512.48h241.992c7.469 0 13.797-5.492 14.852-12.875l6.73-48.12H113.422l6.73 48.12c1.055 7.383 7.383 12.875 14.852 12.875zm0 0M469.48 134.004c-.585-10.68-4.949-21.18-13.093-29.324-17.008-17.008-46.684-16.977-63.633 0-13.649 13.652-16.684 33.968-9.078 50.562-2.934 2.356-6.465 4.059-9.649 6.14 13.668 23.813 21.25 51.458 21.25 80.11h39.543c3.899-7.77 8.227-15.156 13.438-21.664 16.61 7.586 36.91 4.54 50.55-9.082 17.563-17.562 17.614-46.031 0-63.644-8.148-8.145-18.648-12.512-29.328-13.098zm0 0M335.277 45.496C335.277 20.684 315.094.5 290.281.5c-11.515 0-22.031 4.352-30 11.484C252.312 4.848 239.797.5 228.281.5c-24.812 0-44.996 20.184-44.996 44.996 0 19.309 12.215 36.8 29.324 43.188-1.214 10.98-4.304 21.96-8.691 32.812h110.723c-4.387-10.848-7.477-21.828-8.688-32.812 17.11-6.387 29.324-23.88 29.324-43.188zm0 0M331.188 151.492H187.37c-2.223 3.192-4.238 6.426-6.738 9.551-17.637 22.02-27.348 50.582-27.348 80.45h211.992c0-29.868-9.71-58.434-27.347-80.45-2.504-3.121-4.52-6.36-6.743-9.55zm0 0" />
    </svg>
  );
};

export default SvgChickenbucket;
