import * as React from "react";

const SvgWineglass = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="-91 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M268.738 442.273l-88.32-20.312v-52.438c-4.977.375-9.996.582-15.05.582-5.06 0-10.075-.207-15.056-.582v52.438l-88.32 20.312a15.058 15.058 0 00-11.68 14.672v40c0 8.317 6.739 15.055 15.055 15.055h200c8.313 0 15.051-6.738 15.051-15.055v-40a15.058 15.058 0 00-11.68-14.672zm0 0M289.824 10.855A15.057 15.057 0 00275.367 0h-220c-6.699 0-12.59 4.426-14.457 10.855L9.84 117.88h311.055zm0 0M328.43 147.879H2.305c-7.485 44.434 3.375 89.527 30.925 126.21 31.504 41.958 79.668 66.016 132.137 66.016 52.465 0 100.63-24.062 132.137-66.015 27.547-36.684 38.406-81.778 30.926-126.211zm0 0" />
    </svg>
  );
};

export default SvgWineglass;
