import * as React from "react";

const SvgWaffle = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M482 106v240c0 74.441-61.559 136-136 136H106c-5.21 0-10.156-.96-15.21-1.54C110.03 500.22 136.79 512 166 512h240c57.89 0 106-48.11 106-106V166c0-29.21-11.781-55.969-31.54-75.21.58 5.054 1.54 10 1.54 15.21zm0 0" />
      <path d="M361 346v-15h-30v30h15c8.277 0 15-6.723 15-15zm0 0M211 331h30v30h-30zm0 0M211 91h30v30h-30zm0 0M106 361h15v-30H91v15c0 8.277 6.723 15 15 15zm0 0M121 91h-15c-8.277 0-15 6.723-15 15v15h30zm0 0M91 211h30v30H91zm0 0M211 211h30v30h-30zm0 0M361 106c0-8.277-6.723-15-15-15h-15v30h30zm0 0M331 211h30v30h-30zm0 0" />
      <path d="M106 452h240c57.89 0 106-48.11 106-106V106C452 48.11 403.89 0 346 0H106C48.11 0 0 48.11 0 106v240c0 57.89 48.11 106 106 106zM301 76c0-8.29 6.71-15 15-15h30c24.813 0 45 20.188 45 45v30c0 8.29-6.71 15-15 15h-60c-8.29 0-15-6.71-15-15zm0 120c0-8.29 6.71-15 15-15h60c8.29 0 15 6.71 15 15v60c0 8.29-6.71 15-15 15h-60c-8.29 0-15-6.71-15-15zm0 120c0-8.29 6.71-15 15-15h60c8.29 0 15 6.71 15 15v30c0 24.813-20.188 45-45 45h-30c-8.29 0-15-6.71-15-15zM181 76c0-8.29 6.71-15 15-15h60c8.29 0 15 6.71 15 15v60c0 8.29-6.71 15-15 15h-60c-8.29 0-15-6.71-15-15zm0 120c0-8.29 6.71-15 15-15h60c8.29 0 15 6.71 15 15v60c0 8.29-6.71 15-15 15h-60c-8.29 0-15-6.71-15-15zm0 120c0-8.29 6.71-15 15-15h60c8.29 0 15 6.71 15 15v60c0 8.29-6.71 15-15 15h-60c-8.29 0-15-6.71-15-15zM61 106c0-24.813 20.188-45 45-45h30c8.29 0 15 6.71 15 15v60c0 8.29-6.71 15-15 15H76c-8.29 0-15-6.71-15-15zm0 90c0-8.29 6.71-15 15-15h60c8.29 0 15 6.71 15 15v60c0 8.29-6.71 15-15 15H76c-8.29 0-15-6.71-15-15zm0 120c0-8.29 6.71-15 15-15h60c8.29 0 15 6.71 15 15v60c0 8.29-6.71 15-15 15h-30c-24.813 0-45-20.188-45-45zm0 0" />
    </svg>
  );
};

export default SvgWaffle;
