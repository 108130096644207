import * as React from "react";

const SvgKebab = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="-31 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M135 0c-8.29 0-15 6.71-15 15v47.762C102.578 68.977 90 86.469 90 106c0 24.813 20.188 45 45 45s45-20.188 45-45c0-19.531-12.578-37.023-30-43.238V15c0-8.29-6.71-15-15-15zm0 0M300 346c0 8.29 6.71 15 15 15h15v106c0 24.813 20.188 45 45 45s45-20.188 45-45V361h15c8.29 0 15-6.71 15-15s-6.71-15-15-15H315c-8.29 0-15 6.71-15 15zm0 0M385.605 4.395c-5.859-5.86-15.351-5.86-21.21 0C342.539 26.25 330 57.515 330 88.422V301h90V88.422c0-30.906-12.54-62.172-34.395-84.027zm0 0" />
      <path d="M0 436c0 8.29 6.71 14.988 15 14.988h105V497c0 8.29 6.71 15 15 15s15-6.71 15-15v-46.012h105c8.29 0 15-6.699 15-14.988s-6.71-15-15-15h-47.762c1.68-4.715 2.762-9.715 2.762-15a44.97 44.97 0 00-3.164-16.582C225.922 384.218 240 366.715 240 346a44.97 44.97 0 00-3.164-16.582C255.922 324.218 270 306.715 270 286c0-11.566-4.508-22.02-11.703-30 7.195-7.98 11.703-18.434 11.703-30s-4.508-22.02-11.703-30c7.195-7.98 11.703-18.434 11.703-30 0-24.813-20.188-45-45-45h-16.52c-6.968 34.191-37.265 60-73.48 60s-66.512-25.809-73.48-60H45c-24.813 0-45 20.188-45 45 0 11.566 4.508 22.02 11.703 30C4.508 203.98 0 214.434 0 226s4.508 22.02 11.703 30C4.508 263.98 0 274.434 0 286c0 20.742 14.18 38.078 33.305 43.266C31.215 334.449 30 340.078 30 346c0 20.715 14.078 38.219 33.164 43.418A44.97 44.97 0 0060 406c0 5.285 1.082 10.285 2.762 15H15c-8.29 0-15 6.71-15 15zm0 0" />
    </svg>
  );
};

export default SvgKebab;
