import * as React from "react";

const SvgMeatalt = (props) => {
  return (
    <svg
      height="511pt"
      viewBox="0 0 511 511.999"
      width="511pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M166.11 408.844c2.53-1.88 49.913-55.824 163.671-81.781a118.825 118.825 0 0057.567-31.844c46.777-46.778 46.777-122.895 0-169.676-44-44-110.293-45.238-154.82-12.695-31.938 23.363-43.465 53.261-47.516 74.234-12.114 62.613-31.504 107.113-78.883 154.492-18.27 18.266-21.25 46.29-6.805 63.801 18.235 22.055 49.012 21.246 66.785 3.469zm136.402-262.09c17.547-17.543 46.086-17.543 63.629 0s17.543 46.082 0 63.629c-17.543 17.543-46.082 17.543-63.63 0-17.542-17.547-17.542-46.086 0-63.63zm0 0" />
      <path d="M38.969 472.605c53.449 53.454 139 51.633 190.77-.132 2.495-1.86 32.64-37.735 120.058-57.684 38.351-8.75 73.332-28.098 101.18-55.941 81.18-81.184 82.332-213.192.824-295.989-74.774-75.953-190.426-82.55-272.38-22.644-43.32 31.656-72.706 77.762-82.753 129.789-8.688 44.894-19.527 73.3-54.168 107.941-52.473 52.473-58.156 140.035-3.531 194.66zm45.949-152.242c43.133-43.133 59.668-82.27 70.644-138.976 5.067-26.235 19.45-63.63 59.258-92.75 55.375-40.473 138.606-39.434 193.739 15.699 58.468 58.473 58.468 153.621 0 212.09-19.844 19.844-44.782 33.637-72.11 39.883-101.64 23.18-133.847 62.414-149.133 73.746-29.304 29.304-76.738 29.304-106.054-.012-30.707-30.707-26.168-79.855 3.656-109.68zm0 0" />
      <path d="M344.934 167.98c-5.86-5.855-15.352-5.855-21.211 0-5.856 5.856-5.856 15.352 0 21.208 5.859 5.859 15.351 5.859 21.21 0 5.856-5.856 5.856-15.352 0-21.208zm0 0" />
    </svg>
  );
};

export default SvgMeatalt;
