import * as React from "react";

const SvgSkewers = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M76 0c-8.29 0-15 6.71-15 15v16.398C41.602 34.352 21.656 42.628 8.777 55.52c-11.66 11.718-11.761 31.64.043 43.449 11.813 11.777 31.836 11.527 43.41-.043C57.621 93.536 67.93 91 76 91s18.379 2.535 23.77 7.926c11.652 11.71 30.652 11.765 42.421.027 11.825-11.824 11.657-31.781.028-43.406C129.339 42.633 110.402 34.352 91 31.398V15c0-8.29-6.707-15-15-15zm0 0" />
      <path d="M106 121c0 16.57-13.43 30-30 30s-30-13.43-30-30 13.43-30 30-30 30 13.43 30 30zm0 0M142.191 248.953c11.825-11.824 11.657-30.781.028-42.406C126.019 190.3 100.242 181 76 181c-24.23 0-51.02 9.3-67.223 25.52-11.66 11.718-11.761 30.64.043 42.449 11.813 11.777 31.836 11.527 43.41-.043C57.621 243.536 67.93 241 76 241s18.379 2.535 23.77 7.926c11.652 11.71 30.652 11.765 42.421.027zm0 0" />
      <path d="M106 271c0 16.57-13.43 30-30 30s-30-13.43-30-30 13.43-30 30-30 30 13.43 30 30zm0 0M8.82 399.969c11.813 11.777 31.836 11.527 43.41-.043C57.621 394.536 67.93 392 76 392s18.379 2.535 23.77 7.926c11.652 11.71 30.652 11.765 42.421.027 11.825-11.824 11.657-31.781.028-43.406C126.019 340.3 100.242 331 76 331c-24.23 0-51.02 9.3-67.223 25.52-11.66 11.718-11.761 31.64.043 43.449zm0 0" />
      <path d="M106 422c0 16.57-13.43 30-30 30s-30-13.43-30-30 13.43-30 30-30 30 13.43 30 30zm0 0M256 0c-8.29 0-15 6.71-15 15v16.398c-19.398 2.954-38.344 11.23-51.223 24.122-11.66 11.718-11.761 31.64.043 43.449 11.813 11.777 30.836 11.527 42.41-.043C237.621 93.536 247.93 91 256 91s18.379 2.535 23.77 7.926c11.652 11.71 30.652 11.765 42.421.027 11.825-11.824 11.657-31.781.028-43.406-12.88-12.914-31.817-21.195-51.219-24.149V15c0-8.29-6.707-15-15-15zm0 0" />
      <path d="M286 121c0 16.57-13.43 30-30 30s-30-13.43-30-30 13.43-30 30-30 30 13.43 30 30zm0 0M189.82 248.969c11.813 11.777 30.836 11.527 42.41-.043 5.391-5.39 15.7-7.926 23.77-7.926s18.379 2.535 23.77 7.926c11.652 11.71 30.652 11.765 42.421.027 11.825-11.824 11.657-30.781.028-42.406C306.019 190.3 280.242 181 256 181c-24.23 0-50.02 9.3-66.223 25.52-11.66 11.718-11.761 30.64.043 42.449zm0 0" />
      <path d="M286 271c0 16.57-13.43 30-30 30s-30-13.43-30-30 13.43-30 30-30 30 13.43 30 30zm0 0M189.82 399.969c11.813 11.777 30.836 11.527 42.41-.043 5.391-5.39 15.7-7.926 23.77-7.926s18.379 2.535 23.77 7.926c11.652 11.71 30.652 11.765 42.421.027 11.825-11.824 11.657-31.781.028-43.406C306.019 340.3 280.242 331 256 331c-24.23 0-50.02 9.3-66.223 25.52-11.66 11.718-11.761 31.64.043 43.449zm0 0" />
      <path d="M286 422c0 16.57-13.43 30-30 30s-30-13.43-30-30 13.43-30 30-30 30 13.43 30 30zm0 0M451 31.398V15c0-8.29-6.707-15-15-15-8.29 0-15 6.71-15 15v16.398c-19.398 2.954-38.344 11.23-51.223 24.122-11.66 11.718-11.761 31.64.043 43.449 11.813 11.777 30.836 11.527 42.41-.043C417.621 93.536 427.93 91 436 91s18.379 2.535 23.77 7.926c11.652 11.71 31.652 11.765 43.421.027 11.825-11.824 11.657-31.781.028-43.406-12.88-12.914-32.817-21.195-52.219-24.149zm0 0" />
      <path d="M466 121c0 16.57-13.43 30-30 30s-30-13.43-30-30 13.43-30 30-30 30 13.43 30 30zm0 0M436 181c-24.23 0-50.02 9.3-66.223 25.52-11.66 11.718-11.761 30.64.043 42.449 11.813 11.777 30.836 11.527 42.41-.043 5.391-5.39 15.7-7.926 23.77-7.926s18.379 2.535 23.77 7.926c11.652 11.71 31.652 11.765 43.421.027 11.825-11.824 11.657-30.781.028-42.406C487.019 190.3 460.242 181 436 181zm0 0" />
      <path d="M466 271c0 16.57-13.43 30-30 30s-30-13.43-30-30 13.43-30 30-30 30 13.43 30 30zm0 0M436 331c-24.23 0-50.02 9.3-66.223 25.52-11.66 11.718-11.761 31.64.043 43.449 11.813 11.777 30.836 11.527 42.41-.043 5.391-5.39 15.7-7.926 23.77-7.926s18.379 2.535 23.77 7.926c11.652 11.71 31.652 11.765 43.421.027 11.825-11.824 11.657-31.781.028-43.406C487.019 340.3 460.242 331 436 331zm0 0M61 479.871V497c0 8.293 6.71 15 15 15 8.293 0 15-6.707 15-15v-17.129c-4.824 1.25-9.79 2.129-15 2.129-5.207 0-10.176-.875-15-2.129zm0 0M241 479.871V497c0 8.293 6.71 15 15 15 8.293 0 15-6.707 15-15v-17.129c-4.824 1.25-9.79 2.129-15 2.129-5.207 0-10.176-.875-15-2.129zm0 0M421 479.871V497c0 8.293 6.71 15 15 15 8.293 0 15-6.707 15-15v-17.129c-4.824 1.25-9.79 2.129-15 2.129-5.207 0-10.176-.875-15-2.129zm0 0" />
      <path d="M466 422c0 16.57-13.43 30-30 30s-30-13.43-30-30 13.43-30 30-30 30 13.43 30 30zm0 0" />
    </svg>
  );
};

export default SvgSkewers;
