import * as React from "react";

const SvgFishandchips = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="-61 0 512 512.001"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M360.063 15c0-8.29-6.708-15-15-15h-30c-24.618 0-46.317 12.086-60 30.465-13.68-18.38-35.38-30.465-60-30.465h-30c-8.29 0-15 6.71-15 15 0 41.129 33.3 74.57 74.343 74.934-1.43 1.925-2.734 3.632-4.265 5.734 20.554 9.531 34.922 30.223 34.922 54.332v80.082l89.69-40.898c-16.065-38.239-41.456-75.688-58.96-99.258 41.012-.403 74.27-33.82 74.27-74.926zm0 0M386.465 215.398c-4.2-5.097-11.402-6.898-17.703-4.199l-292.2 132.903 105.903 161.296c2.996 4.204 7.8 6.602 12.597 6.602 4.848 0 10.047-2.77 12.598-6.602l180-272c3.602-5.699 3.305-12.898-1.195-18zm0 0" />
      <path d="M195.063 120c-8.282 0-15 6.715-15 15v115.59l15.015 6.844 29.984-13.672V150c0-16.57-13.43-30-30-30zm0 0M3.664 215.398c-4.5 5.102-4.8 12.301-1.2 18l57.177 85.418 99.203-44.93L21.364 211.2c-6.301-2.699-13.5-.898-17.7 4.2zm0 0M105.063 216.395l45 20.515V225c0-24.852-20.145-45-45-45zm0 0M30.063 182.645c1.03.355 2.097.542 3.105.972l41.895 19.102V105c0-8.285-6.715-15-15-15-16.567 0-30 13.43-30 30zm0 0" />
    </svg>
  );
};

export default SvgFishandchips;
