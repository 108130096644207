import * as React from "react";

const SvgWaiter = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 0 512 512.001"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M37.402 247.148C15.062 260.148 0 284.34 0 312c0 39.105 30.09 71.3 68.332 74.688C47.832 353.27 36 313.991 36 272c0-8.36.48-16.652 1.402-24.852zm0 0M474.602 247.148A221.2 221.2 0 01476 272c0 41.992-11.832 81.27-32.328 114.688C481.91 383.3 512 351.105 512 312c0-27.656-15.059-51.852-37.398-64.852zm0 0M98.133 118.793c8.422-8.68 20.195-13.656 32.297-13.656a45.21 45.21 0 0119.937 4.66l30.313 14.984c65.101 32.18 137.898 49.188 210.52 49.188h31.612c19.692 0 36.926 12.61 42.887 31.379.246.77.477 1.547.715 2.324l22.746 13.238c-3.043-57.637-26.926-111.398-68.05-152.52C377.007 24.29 318.37 0 256 0S134.992 24.29 90.89 68.39c-41.12 41.122-65.007 94.883-68.05 152.52l22.7-13.21c10.07-33.055 27.91-63.477 52.593-88.907zm0 0M355.516 414.684c-3.243-1.84-6.707-2.758-10.168-2.758-3.602 0-7.2.996-10.535 2.984l-40.184 23.996A44.696 44.696 0 01301 461.957a44.69 44.69 0 01-6.383 23.07l40.196 24c3.257 1.946 6.902 2.973 10.535 2.973 3.488 0 7.004-.95 10.164-2.742 6.664-3.785 10.488-10.363 10.488-18.051v-58.473c0-7.687-3.82-14.265-10.484-18.05zm0 0" />
      <path d="M437.105 214.43a14.994 14.994 0 00-14.296-10.457h-31.61c-77.207 0-154.601-18.086-223.812-52.297l-30.317-14.988a15.002 15.002 0 00-17.41 3C85.06 175.34 66 222.327 66 272c0 52.895 21.734 100.805 56.734 135.285 4.41-7.64 10.856-14.101 18.942-18.691 16.035-9.102 35.058-8.895 50.89.558l49.997 29.856A44.812 44.812 0 01256 416.957c4.676 0 9.191.719 13.434 2.05l50-29.855c15.828-9.453 34.851-9.66 50.89-.558 8.086 4.59 14.531 11.05 18.942 18.687C424.266 372.805 446 324.895 446 272c0-19.621-2.992-38.992-8.895-57.57zM190 294c-8.285 0-15-6.715-15-15s6.715-15 15-15 15 6.715 15 15-6.715 15-15 15zm66 92c-24.813 0-45-20.188-45-45 0-8.285 6.715-15 15-15s15 6.715 15 15c0 8.27 6.73 15 15 15 8.273 0 15-6.73 15-15 0-8.285 6.715-15 15-15s15 6.715 15 15c0 24.813-20.188 45-45 45zm66-92c-8.285 0-15-6.715-15-15s6.715-15 15-15 15 6.715 15 15-6.715 15-15 15zm0 0" />
      <path d="M217.371 438.906l-40.184-23.996c-6.539-3.902-14.082-3.984-20.699-.226-6.664 3.78-10.488 10.359-10.488 18.05v58.473c0 7.688 3.824 14.266 10.488 18.05 3.164 1.794 6.676 2.743 10.164 2.743 3.633 0 7.278-1.027 10.536-2.973l40.195-24a44.738 44.738 0 01-6.383-23.07 44.733 44.733 0 016.371-23.05zm0 0M256 446.957c-8.27 0-15 6.73-15 15s6.73 15 15 15c8.273 0 15-6.73 15-15 0-8.273-6.727-15-15-15zm0 0" />
    </svg>
  );
};

export default SvgWaiter;
