import * as React from "react";

const SvgBurrito = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="-91 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M100.914 152.07C95.48 153.68 90 158.602 90 166s5.48 12.32 10.914 13.93c.715.215 1.238.757 1.902 1.07H105c5.105 0 10.094.477 15 1.195V166c0-24.813 20.188-45 45-45s45 20.188 45 45v16.2c4.934-.747 9.934-1.2 15-1.2h2.184c.668-.313 1.187-.855 1.902-1.07C234.52 178.32 240 173.398 240 166s-5.48-12.32-10.914-13.93a15.024 15.024 0 01-9.594-8.644 15.048 15.048 0 01.672-12.906c2.711-4.965 3.094-12.317-2.137-17.532-5.214-5.273-12.566-4.879-17.55-2.152-3.954 2.168-8.7 2.414-12.903.672a15.024 15.024 0 01-8.644-9.594C177.32 96.48 172.398 91 165 91s-12.32 5.48-13.93 10.914a15.024 15.024 0 01-8.644 9.594 14.955 14.955 0 01-12.906-.672c-4.95-2.727-12.317-3.106-17.532 2.137-5.246 5.23-4.863 12.582-2.152 17.55a15.053 15.053 0 01.672 12.903 15.024 15.024 0 01-9.594 8.644zm0 0" />
      <path d="M150 166v25.426c5.29 2.547 10.39 5.414 15.156 8.765 4.723-3.293 9.707-6.125 14.844-8.62V166c0-8.277-6.723-15-15-15s-15 6.723-15 15zm0 0" />
      <path d="M330 166C330 75.02 255.98 0 165 0S0 75.02 0 166v15h62.918C61.191 176.258 60 171.29 60 166c0-14.547 7.016-27.656 18.414-35.875-2.258-13.871 2.063-28.094 12.348-38.379 10.285-10.27 24.668-14.473 38.363-12.332C137.344 68.016 150.453 61 165 61s27.656 7.016 35.875 18.414c13.844-2.227 28.094 2.078 38.379 12.348 10.27 10.27 14.59 24.508 12.332 38.363C262.984 138.344 270 151.453 270 166c0 5.262-1.04 10.277-2.754 15H330zm0 0M255 241h75v-30H225c-13.438 0-26.46 3.68-37.879 10.375C201.227 239.254 210 261.515 210 286v15h45c8.29 0 15 6.71 15 15s-6.71 15-15 15h-45v181h15c52.754 0 96.129-40.25 103.484-91H255c-8.29 0-15-6.71-15-15s6.71-15 15-15h75V271h-75c-8.29 0-15-6.71-15-15s6.71-15 15-15zm0 0M180 512V391h-45c-8.29 0-15-6.71-15-15s6.71-15 15-15h45v-75c0-41.352-33.648-75-75-75H0v60h75c8.29 0 15 6.71 15 15s-6.71 15-15 15H0v105c0 5.137.8 10.059 1.516 15H105c8.29 0 15 6.71 15 15s-6.71 15-15 15H10.531c16.922 35.344 52.742 61 94.469 61zm0 0" />
    </svg>
  );
};

export default SvgBurrito;
