import * as React from "react";

const SvgCakeslice = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 -21 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 455c0 8.281 6.715 15 15 15h482c8.281 0 15-6.719 15-15v-35H0zm0 0M0 340h512v50H0zm0 0M0 260h512v50H0zm0 0M15 230h482c6.695 0 12.582-4.441 14.422-10.879s-.813-13.316-6.496-16.855l-73.496-45.743C415.992 188.156 383.5 210 346 210c-52.383 0-95-42.617-95-95 0-21.957 7.492-42.195 20.047-58.3l-7.121-4.434c-4.852-3.02-11-3.02-15.852 0l-241 150c-5.683 3.539-8.336 10.418-6.496 16.855S8.305 230 15 230zm0 0" />
      <path d="M346 180c35.84 0 65-29.16 65-65 0-29.828-20.203-55.008-47.633-62.625C368.441 39.301 381.152 30 396 30c8.281 0 15-6.715 15-15s-6.719-15-15-15c-31.191 0-57.309 22.086-63.566 51.434C303.086 57.69 281 83.813 281 115c0 35.84 29.16 65 65 65zm0 0" />
    </svg>
  );
};

export default SvgCakeslice;
