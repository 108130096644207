import * as React from "react";

const SvgMilkshake = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="-91 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M60 150c0 16.57 13.43 30 30 30h210c16.57 0 30-13.43 30-30s-13.43-30-30-30H90c-16.57 0-30 13.43-30 30zm0 0M313.734 210H76.554c-7.917 15.96-13.03 35.313-14.831 56.816-2.797 33.418 2.57 68.504 15.117 98.797 10.398 25.098 25.582 46.106 43.91 60.754 12.793 10.227 26.945 17.399 42.293 21.508l-38.516 38.52a14.994 14.994 0 00-3.254 16.343 15.006 15.006 0 0013.86 9.262h120a15.006 15.006 0 0013.86-9.262 15 15 0 00-3.255-16.343l-38.59-38.59c37.829-10.25 68.418-38.985 86.348-82.278 12.543-30.277 17.906-65.332 15.106-98.707-1.805-21.496-6.93-40.851-14.868-56.82zm0 0M25.605 55.605c9.782-9.78 24.954-10.167 35.696-2.054C60.816 55.69 60 57.71 60 60c0 16.566 13.43 30 30 30s30-13.434 30-30c0-16.57-13.43-30-30-30-2.781 0-5.285.871-7.84 1.582-22.531-19.273-56.453-18.5-77.765 2.813-5.86 5.859-5.86 15.351 0 21.21s15.351 5.86 21.21 0zm0 0M298.988 62.504C293.176 26.879 259.996 0 221.793 0H165c-8.29 0-15 6.71-15 15s6.71 15 15 15h30c8.277 0 15 6.723 15 15s-6.723 15-15 15h-30c-5.172 0-10.2 1.055-15.21 2.098-.36 10.172-3.306 19.62-8.134 27.902h156.422c1.832-8.945 2.422-18.2.91-27.496zm0 0" />
    </svg>
  );
};

export default SvgMilkshake;
