import * as React from "react";

const SvgBread = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M421.773 322.121l-1.543 4.621-20 60c-2.093 6.285-7.949 10.262-14.226 10.262-1.574 0-3.176-.25-4.746-.774-7.86-2.62-12.106-11.113-9.488-18.972L390.187 322h-38.375l-1.582 4.742-20 60c-2.097 6.285-7.949 10.262-14.23 10.262-1.57 0-3.172-.25-4.742-.774-7.86-2.62-12.11-11.117-9.488-18.972L320.187 322h-38.375l-1.582 4.738v.004l-20 60c-2.097 6.285-7.949 10.262-14.23 10.262-1.57 0-3.172-.25-4.746-.774-7.86-2.62-12.106-11.117-9.484-18.972L250.187 322h-38.375l-1.582 4.738v.004l-20 60c-2.093 6.285-7.949 10.262-14.23 10.262-1.57 0-3.172-.25-4.746-.774-7.86-2.62-12.106-11.113-9.484-18.972L180.187 322h-38.375l-1.578 4.734c-.004.004-.004.004-.004.008l-20 60c-2.093 6.285-7.949 10.262-14.226 10.262-1.574 0-3.172-.25-4.746-.774-7.86-2.62-12.11-11.117-9.488-18.972L110.187 322H95c-52.383 0-95 42.617-95 95s42.617 95 95 95h322c52.383 0 95-42.617 95-95 0-50.781-40.055-92.387-90.227-94.879zm0 0M367 0H242.879C289.379 31.488 320 84.734 320 145c0 29.691-15.66 56.797-40 72.078V275c0 5.258-.914 10.305-2.578 15H457c8.285 0 15-6.715 15-15v-77.078c23.063-6.547 40-27.793 40-52.922C512 65.047 446.953 0 367 0zm0 0" />
      <path d="M145 0C65.047 0 0 65.047 0 145c0 25.129 16.938 46.375 40 52.922V275c0 8.285 6.715 15 15 15h180c8.285 0 15-6.715 15-15v-77.078c23.063-6.547 40-27.793 40-52.922C290 65.047 224.953 0 145 0zm0 0" />
    </svg>
  );
};

export default SvgBread;
