import * as React from "react";

const SvgStore = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.005 512.005"
      {...props}
    >
      <path d="M509.123 166.723l-80-112c-3.2-4.16-8-6.72-13.12-6.72h-320c-5.12 0-9.92 2.56-13.12 6.72l-80 112-2.88 8.64c-.32 35.52 28.48 64.64 64 64.64 19.2 0 36.16-8.32 48-21.76 11.84 13.44 28.8 21.76 48 21.76s36.16-8.32 48-21.76c11.84 13.44 28.8 21.76 48 21.76s36.16-8.32 48-21.76c11.84 13.44 28.8 21.76 48 21.76s36.16-8.32 48-21.76c11.84 13.44 28.8 21.76 48 21.76 35.52 0 64.32-29.12 64-64.64l-2.88-8.64zM448.003 272.003a95.746 95.746 0 01-48-12.928c-28.928 16.736-67.072 16.736-96 0-28.928 16.736-67.104 16.736-96 0-28.896 16.736-67.104 16.736-96 0-24.032 13.952-53.792 15.744-80 6.688v166.24c0 17.664 14.336 32 32 32h256v-160h96v160h32c17.664 0 32-14.336 32-32V266.147c-10.176 3.616-20.896 5.856-32 5.856zm-224 128h-128v-96h128v96z" />
    </svg>
  );
};

export default SvgStore;
