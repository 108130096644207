import * as React from "react";

const SvgRestaurant = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path d="M498.682 435.326L297.917 234.56 63.357 0H45.026l-3.743 9.511c-9.879 25.104-14.1 50.78-12.205 74.249 2.16 26.752 12.323 49.913 29.392 66.982l183.11 183.11 24.152-24.152 169.285 189.293c16.84 16.84 45.825 17.84 63.665 0 17.554-17.554 17.554-46.114 0-63.667zM156.728 291.442L13.317 434.853c-17.552 17.552-17.552 46.113 0 63.665 16.674 16.674 45.519 18.146 63.665 0l143.412-143.412-63.666-63.664zM490.253 85.249l-81.351 81.35-21.223-21.222 81.351-81.351-21.222-21.222-81.35 81.35-21.222-21.222 81.351-81.35L405.366.361l-106.11 106.11a74.903 74.903 0 00-21.828 48.535c-.277 4.641-1.329 9.206-3.074 13.548l68.929 68.929c4.342-1.747 8.908-2.798 13.548-3.075a74.887 74.887 0 0048.535-21.827l106.11-106.109-21.223-21.223z" />
    </svg>
  );
};

export default SvgRestaurant;
