import * as React from "react";

const SvgWinebottle = (props) => {
  return (
    <svg
      height="511pt"
      viewBox="-40 0 511 512"
      width="511pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M120.273 46.473l-22.5-38.97C93.633.329 84.457-2.128 77.285 2.013l-69.281 40C.828 46.156-1.63 55.328 2.512 62.504l22.5 38.969zm0 0M242.5 397V252H22.426c-9.649 0-16.785 8.973-14.617 18.371l53.078 230A14.996 14.996 0 0075.5 512h282a15 15 0 0014.617-11.629L385.59 442h-98.086c-24.856 0-45.004-20.148-45.004-45zm0 0" />
      <path d="M417.5 252h-145v145c0 8.285 6.719 15 15 15h130c8.285 0 15-6.715 15-15V267c0-8.285-6.715-15-15-15zm0 0M75.895 189.61c-9.22 8.726-15.696 20.019-18.618 32.39h222.07l-33.273-57.633c-15.34-26.57-46.734-38.215-74.926-29.777l-35.875-62.137-95.261 55zm0 0" />
    </svg>
  );
};

export default SvgWinebottle;
