import * as React from "react";

const SvgSearching = (props) => {
  return (
    <svg
      height="512pt"
      viewBox="0 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M454.887 57.113C418.055 20.285 369.086 0 317 0S215.945 20.285 179.113 57.113c-72.46 72.461-75.863 188.235-10.203 264.762l-28.933 28.938c-23-13.555-53.172-10.47-72.907 9.265l-49.496 49.496C6.242 420.906 0 435.973 0 452s6.242 31.094 17.574 42.426S43.973 512 60 512s31.094-6.242 42.426-17.574l49.5-49.496c19.73-19.735 22.82-49.907 9.262-72.907l28.937-28.933C225.438 373.44 269.945 390 317 390c52.086 0 101.055-20.281 137.887-57.113 76.027-76.032 76.027-199.742 0-275.774zM437 247H197c-8.285 0-15-6.715-15-15s6.715-15 15-15h5.977c6.73-51.438 47.585-92.293 99.023-99.023V107h-5c-8.285 0-15-6.715-15-15s6.715-15 15-15h40c8.285 0 15 6.715 15 15s-6.715 15-15 15h-5v10.977c51.438 6.73 92.293 47.585 99.023 99.023H437c8.285 0 15 6.715 15 15s-6.715 15-15 15zm0 0" />
      <path d="M317 147c-41.75 0-76.57 30.262-83.676 70h167.352c-7.106-39.738-41.926-70-83.676-70zm0 0" />
    </svg>
  );
};

export default SvgSearching;
